import React, { useState, useContext, useEffect } from "react";
import { Container, Row } from 'react-bootstrap';
import contextannonces from './contextannonces'
import Location from './location';
import DetailLocation from './detailLocation';
import noimage from '../images/no-image.png';
import { useHistory } from "react-router-dom";
import { Col } from 'react-bootstrap';
import Gotodisplay from "./gotodisplay";



import $ from 'jquery';


const Louer = (props) => {
  const { listeAnnonces } = useContext(contextannonces);

  const [gotop, setGotop] = useState(true);
  Gotodisplay(gotop,setGotop)
  let history = useHistory();
  const clicContact = () => {
      history.push('/contact')
  }



  

  // recupere les biens à la louer seulement
  const locationOnly = listeAnnonces.filter(
    function (bien) {
      return (bien.location_id !== null  && bien.statut.substring(1) !== "Archive" && bien.statut.substring(1) !== "Vendu")
    }
  );


  const nobientoshow = (

                <Col md={{ span: 8, offset: 2 }} className="addpadding2">
                  <span className="txttitre">Nous sommes désolés, nous n'avons pas de bien en location en ce moment.</span>
                </Col>
  )

  const handleOnClick = (objBien) => {
    /*        
            console.log('************')
            console.log(objBien)
            console.log('************')
            */
            props.history.push('/DetailLocation/' + objBien.aff_num)
            DetailLocation.defaultProps = { sel: objBien }
        }


  const [maliste, setMaliste] = useState(locationOnly)
  


  const testIfImgExist = (monBien)=>{
    //console.log(monBien.imagesbien)
    //let ret = monBien.imagesbien[0].img;
    let ret;
    if (monBien.imagesbien.length ===0){
        ret = noimage;
    }else{
        ret = monBien.imagesbien[0].img;
    }

    return ret; 
    }

    useEffect(() => {
      $(".mnu").removeClass("menuSelected") 
      $("#locLink").addClass("menuSelected") 

        // Workaround in case page loaded directly
        if (listeAnnonces.length > 0)  {
          setMaliste(locationOnly)
      }

    },[listeAnnonces])




  return (

<Container  className="containerAib">
<Row>
        <div className="col-md-12 ">
          <ol className="breadcrumb">
            <li className="active">&nbsp;&nbsp;Louer un bien</li>
          </ol>
        </div>
      </Row>
<div className="row">
  
  {(maliste.length === 0 ) ? nobientoshow : 
    maliste.map((monBien, index) => (
      
      <div key={index} className="col-md-4 col-centered" onClick={()=> handleOnClick(monBien)}>
        
          <Location
              key={monBien.id}
              aff_num={monBien.aff_num}
              loyer_prix={monBien.location.LOYER}
              loyer_charge={monBien.location.PROVISION_SUR_CHARGES}
              titre={monBien.intitule.fr}
              date_maj={monBien.date_maj}
              
              img={<img src={testIfImgExist(monBien)} className="card-img-top " width="370" alt={index} />}
          />
      </div>
  ))}
</div>
<div className="row titreaib">
                    <div className="col-md-12 col-centered">
                        <span className="addcursor" onClick={clicContact}>VOUS N'AVEZ PAS TROUVE VOTRE IDEAL CLIQUEZ ICI POUR NOUS CONTACTER</span>
                    </div>
                </div>
</Container>
  )
}

export default Louer;



