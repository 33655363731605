import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Container } from 'react-bootstrap';
import Modalpopuplogique from './modalpopuplogique';
import Modalpopup from './modalpopup';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import MapAib from './mapaib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import Gotodisplay from "./gotodisplay";

import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import MessageInfosRecueillies from './messageInfoRecueillies'




const Contact = (props) => {

 
 const flecheCarreRight = <FontAwesomeIcon icon={faCaretRight} className="aib-color" /> 
 const [gotop, setGotop] = useState(true);
  Gotodisplay(gotop,setGotop)

  const [optionReference, setoptionReference] = useState(false);
  let { slug } = useParams();


  const { affiche, toggle } = Modalpopuplogique();

  const [prenom, setPrenom] = useState('');
  const [nom, setNom] = useState('');
  const [typedemande, setTypedemande] = useState("Je suis intérressé par");
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emailconfirmation, setEmailconfirmation] = useState('');
  const [message, setMessage] = useState('');
  const [rgpd, setRgpd] = useState(false);

  const [prenomError, setPrenomError] = useState('');
  const [nomError, setNomError] = useState('');
  const [typedemandeError, setTypedemandeError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailconfirmationError, setEmailconfirmationeError] = useState('');
  const [rgpdError, setRgpdError] = useState('');


  const [typedemande1, setTypedemande1] = useState('')
  const [prenom1, setPrenom1] = useState('')
  const [nom1, setNom1] = useState('')
  const [phone1, setPhone1] = useState('')
  const [email1, setEmail1] = useState('')
  const [emailconfirmation1, setEmailconfirmation1] = useState('')

  const init = () => {

    setTypedemande1("Je suis intérressé par");
    setPrenom1('');
    setNom1('');
    setPhone1('');
    setEmail1('');
    setEmailconfirmation1('');
    setMessage('');
    setPrenomError('');
    setNomError('');
    setTypedemandeError('');
    setPhoneError('');
    setEmailError('');
    setEmailconfirmationeError('');
    setFormValid(0);
    setRgpdError('');
    setRgpd(!rgpd);
    
  }

  const ChoixInteret = () => (

    <div className="form-group">
      <label htmlFor="typedemande" className="control-label above"></label>
      <span className="mandatory">*</span><select id="typedemande" name="typedemande" type="text" value={typedemande1} onChange={e => validateTypedemande(e.target.value)}>
        <option id="votredemande" defaultValue >Je suis intérressé par</option>
        <option>L'achat d'un bien</option>
        <option>La vente d'un bien</option>
        <option>Une autre demande</option>
      </select>
      <div className="help-block with-errors">&nbsp;{typedemandeError}</div>
    </div>
  )



  const SlugExist = () => (

    <span className="txttitre">Je suis interressé par la référence : {slug}</span>

  )



  const checkSlug = () => (
    (slug !== undefined) ? setoptionReference(true) : setoptionReference(false)
  )

  useEffect(() => {

    $(".mnu").removeClass("menuSelected")
    $("#contactLink").addClass("menuSelected")
    checkSlug();
  })












  /* champ de bit pour validation du formulaire  ==> 0b111111 = 63 dec*/
  const [formValid, setFormValid] = useState(0);


  /* validation du prénom */




  const validateMessage = valTextaerea => {
    //console.log(valTextaerea)
    //setMessage(valTextaerea)
    setMessage(valTextaerea)
  }

  /* Validation du Prénom */
  const validatePrenom = valfield => {
    let valid = false;
    let champBit = formValid;

    if (valfield.length > 2)
      valid = true;
    if (valid) {
      setPrenom(valfield)
      setPrenomError("")
      champBit = champBit | (1 << 0)
      setFormValid(champBit)
    } else {
      setPrenomError("Prénom invalide")
      champBit = champBit & ~(1 << 0)
      setFormValid(champBit)
    }
    setPrenom1(valfield)
  }

  /* Validation du Nom */
  const validateNom = valfield => {
    let valid = false;
    let champBit = formValid;

    if (valfield.length > 2)
      valid = true;


    if (valid) {
      setNom(valfield)
      setNomError("")
      champBit = champBit | (1 << 1)
      setFormValid(champBit)
    } else {
      setNomError("Nom invalide")
      champBit = champBit & ~(1 << 1)
      setFormValid(champBit)
    }
    setNom1(valfield)
  }

  /* Validation du Phone */
  const validatePhone = valfield => {
    let champBit = formValid;
    let valid = false;
    let valid1 = false;
    let numbers = /^[-+]?[0-9]+$/;

    (valfield.length === 10) ? valid = true : valid = false;
    (valfield.match(numbers)) ? valid1 = true : valid1 = false;


    if (valid && valid1) {
      setPhone(valfield)
      setPhoneError("")
      champBit = champBit | (1 << 2)
      setFormValid(champBit)
    } else {
      setPhoneError("Téléphone invalide")
      champBit = champBit & ~(1 << 2)
      setFormValid(champBit)
    }
    setPhone1(valfield)
  }

  /* Validation du Email */
  const validateEmail = valfield => {
    let champBit = formValid;
    let valid = false;
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    (valfield.match(regexEmail)) ? valid = true : valid = false;

    if (valid) {
      setEmail(valfield)
      setEmailError("")
      champBit = champBit | (1 << 3)
      setFormValid(champBit)
    } else {
      setEmailError("Email invalide")
      champBit = champBit & ~(1 << 3)
      setFormValid(champBit)
    }
    setEmail1(valfield)
  }

  /* Validation du EmailConfirmation */
  const validateEmailconfirmation = valfield => {
    setEmailconfirmation1(valfield)
    let champBit = formValid;
    let valid = false;
    let valid1 = false;
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    (valfield.match(regexEmail)) ? valid = true : valid = false;
    (valfield === email) ? valid1 = true : valid1 = false;

    if (valid) {
      setEmailconfirmation(valfield)
      setEmailconfirmationeError("")
    } else {
      setEmailconfirmationeError("Email invalide")
      champBit = champBit & ~(1 << 4)
      setFormValid(champBit)
      return;
    }

    if (valid1) {
      setEmailconfirmation(valfield)
      setEmailconfirmationeError("")
    } else {
      setEmailconfirmationeError("Email différent")
      champBit = champBit & ~(1 << 4)
      setFormValid(champBit)

    }


    if (valid && valid1) {
      setEmailconfirmation(valfield)
      setEmailconfirmationeError("")
      champBit = champBit | (1 << 4)
      setFormValid(champBit)
    } else {
      setEmailconfirmationeError("Email différent")
      champBit = champBit & ~(1 << 4)
      setFormValid(champBit)
    }

  }

  /* Validation du Type de demande */
  const validateTypedemande = valfield => {

    let champBit = formValid;
    let valid = false;

    (valfield !== "Je suis intérressé par") ? valid = true : valid = false;

    if (valid) {
      setTypedemande(valfield)
      setTypedemandeError("")
      champBit = champBit | (1 << 5)
      setFormValid(champBit)
    } else {
      setTypedemande("Je suis intérressé par")
      setTypedemandeError("Veuillez choisir un type de demande")
      champBit = champBit & ~(1 << 5)
      setFormValid(champBit)
    }
    setTypedemande1(valfield)
  }
  const validateRgpd = () => {
    
    let champBit = formValid;
    let valid = false;
    
    (rgpd) ? valid = false : valid = true;
    if (valid) {
      champBit = champBit | (1 << 6)
      setRgpdError("")
      setFormValid(champBit)
    }else{

      champBit = champBit & ~(1 << 6)
      setRgpdError("Merci de valider le RGPD !")
      setFormValid(champBit)
    }

    setRgpd(!rgpd)
    
  }
  

  const handleSubmit = event => {
    event.preventDefault();
    //console.log(formValid);
//console.log(slug)
    let valTocompare = 127;

    (slug === undefined ) ? valTocompare = 127 : valTocompare = 95 

    //champBit = champBit | (1 << 5)
    if (formValid === valTocompare) {
      axios.post(
        //'http://localhost:3001/contact',
        'https://www.aibimmobilier.fr:444/contact',
        {
          prenom: prenom,
          nom: nom,
          typedemande: typedemande,
          phone: phone,
          email: email,
          emailconfirmation: emailconfirmation,
          message: message,
          referencebien: slug
          

        }
      ).then(res => {
        //console.log(res);
        //console.log(res.data);
        toggle();
        init();
      })
    } else {
      (prenom === '') ? setPrenomError("Merci de renseigner votre prénom !") : setPrenomError("");
      (nom === '') ? setNomError("Merci de renseigner votre nom !") : setNomError("");
      (phone === '') ? setPhoneError("Merci de renseigner votre téléphone !") : setPhoneError("");
      (email === '') ? setEmailError("Merci de renseigner votre email !") : setEmailError("");
      (emailconfirmation === '') ? setEmailconfirmationeError("Merci de renseigner votre email !") : setEmailconfirmationeError("");
      (typedemande === "Je suis intérressé par") ? setTypedemandeError("Merci de choisir un type de demande !") : setTypedemandeError("");
      (rgpd === false) ? setRgpdError("Merci de valider le RGPD !") : setRgpdError("");

    }
  }
  const fileRGPD = "/2018-informations_RGPD_AIB.pdf"








  return (
    <React.Fragment>

      <form onSubmit={handleSubmit}>
        <Container className="containerAib">
          <Row>
            <div className="col-md-12 ">
              <ol className="breadcrumb">
                <li className="active">&nbsp;&nbsp;Nous contacter</li>
              </ol>
            </div>
          </Row>
          <Row className="aib-agence1 ">



            <Col md={{ span: 10, offset: 1 }} className="divaibblock1 ">
              <Row>
                <Col md={{ span: 10, offset: 1 }} className="addpadding2">
                  <span className="txttitre">Nous vous remercions de votre contact et vous recontactons dans les plus brefs délais</span>
                </Col>
                <Col md={4}>
                  <Row>
                    <Col md={12}>

                      <p className="txttitre">Nos coordonnées</p>
                      <p>
                      <span><FontAwesomeIcon icon={faAddressCard} color="#AC0000" /> </span> 
                         <b>Aib Immobilier</b><br/>
                        22 Place de l'Eglise<br/>
                        78660 ABLIS
                </p>
                      
                <p><span><FontAwesomeIcon icon={faPhoneAlt} color="#AC0000" /></span> 09 86 48 27 10</p>
<p>
                      <span><FontAwesomeIcon icon={faEnvelope} color="#AC0000" /></span> <a href='mailto:contact@aibimmobilier.fr'>contact@aibimmobilier.fr</a>
                </p>

                    </Col>

                  </Row>


                </Col>
                <Col md={8} >
                  <Row>
                    <Col md={6}>
                      <div className="form-group ">
                        <label htmlFor="prenom" className="control-label above"></label>
                        <span className="mandatory">*</span><input  size="16" placeholder="Votre prénom" id="prenom" name="prenom" value={prenom1} type="text" onChange={e => validatePrenom(e.target.value)} />
                        <div className="help-block with-errors">&nbsp;{prenomError}</div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group">
                        <label htmlFor="nom" className="control-label above"></label>
                        <span className="mandatory">*</span><input size="16" placeholder="Votre nom" id="nom" name="nom" type="text" value={nom1} onChange={e => validateNom(e.target.value)} />
                        <div className="help-block with-errors">&nbsp;{nomError}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="form-group">
                        <label htmlFor="phone" className="control-label above"></label>
                        <span className="mandatory">*</span><input size="16" placeholder="Votre téléphone" id="phone" name="phone" type="text" value={phone1} onChange={e => validatePhone(e.target.value)} />
                        <div className="help-block with-errors">&nbsp;{phoneError}</div>
                      </div>
                    </Col>
                    <Col md={6}>

                      <div className="form-group">
                        <label htmlFor="email" className="control-label above"></label>
                        <span className="mandatory">*</span><input size="20" placeholder="Votre email" id="email" name="email" type="text" value={email1} onChange={e => validateEmail(e.target.value)} />
                        <div className="help-block with-errors">&nbsp;{emailError}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} >
                      {optionReference ? <SlugExist /> : <ChoixInteret />}

                    </Col>
                    <Col md={6}>
                      <div className="form-group">
                      <span className="mandatory">*</span><label htmlFor="emailconfirmation" className="control-label above"></label>
                        <input size="20" placeholder="Confirmez votre email" id="emailconfirmation" value={emailconfirmation1} name="emailconfirmation" type="text" onChange={e => validateEmailconfirmation(e.target.value)} />
                        <div className="help-block with-errors">&nbsp;{emailconfirmationError}</div>
                      </div>

                    </Col>

                  </Row>

                  <Row>
                    <Col md={12} >
                      <div className="form-group">
                        <label htmlFor="message" className="control-label above">Texte libre (facultatif)</label>
                        <textarea id="message" name="message" onChange={e => validateMessage(e.target.value)} value={message} rows="5" cols="40">
                          {message}
                        </textarea>

                      </div>
                    </Col></Row>
                 
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 10, offset: 1 }} >
                        <div className="form-group">
                          <label htmlFor="rgpd" className="control-label above"></label>
                          <input id="rgpd" type="checkbox" className="alignLeft" checked={rgpd}  onChange={e => validateRgpd()}/>
                          
                      <span className="alignLeft">J'accepte le traitement de mes données personnelles conformément au RGPD. 
                      <a href={fileRGPD} target="_blank" rel="noopener noreferrer"> En savoir plus</a> 
                      </span>
                      <div className="help-block with-errors">&nbsp;{rgpdError}</div>
                      </div>
                </Col>
              </Row>
              <Row>
              <Row>
                <Col md={{ span: 10, offset: 1 }} >
                      <MessageInfosRecueillies/>
                </Col>
              </Row>
              <Row></Row>  
                <Col md={{ span: 4, offset: 5 }} >
                  <div className="form-group">
                    <button className="btn btn-primary buttonInfo" >Envoyer</button>
                  </div>
                </Col>

              </Row>
              <Row>
              <Col md={{ span: 10, offset: 1 }} className="addpadding1">
                      <MapAib />
                    </Col>
                  </Row>
            </Col>
          </Row>

        </Container>

      </form>
      <Modalpopup
        affiche={affiche}
        cache={toggle}
        email={email}
      />
    </React.Fragment>
  )
}

export default Contact;