import React , { useEffect, useState } from "react";
import { Container, Row } from 'react-bootstrap';
// import axios from "axios";



import ImgAibFB from '../images/QrCodeAIBFB.png'
import ImgAibInsta from '../images/QrCodeAIBInsta.png'
import styled from 'styled-components'

const ImgAibstyled = styled.img`
margin-top: 20px;
alt: AIB;
// height: 180px;  // commenté pour Noel
height: 160px;
`



const Logoaccueil = () => {
    
    const url = "https://widgets.meilleursagents.com/feedback.js?version=v2&type=1&size=small&id=53114"
    let [stateMeilleursagents, setStateMeilleursagents] = useState(url ? "loading" : "idle");

    const [reviews, setReviews] = useState([]);
   

    useEffect(() => {

        // const apiKey = 'AIzaSyDPwFRPOJp-oMOLpZp0zDZ_eNKxawJ0zRM'
        // const placeId = '14262484371551721833'
        // axios.get(`https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=name,rating,reviews&key=${apiKey}`)
        // .then(response => {
        //   const place = response.data.result;
        //   setReviews(place.reviews);
        // })
        // .catch(error => {
        //   console.error('Erreur lors de la récupération des avis:', error);
        // });
        



        if (!url) {
            setStateMeilleursagents("idle");
          return;
         }

    let script = document.querySelector(`script[src="${url}"]`);
    
    const handleScript = (e) => {
      setStateMeilleursagents(e.type === "load" ? "ready" : "error");
    };
    
    if (!script) {
      script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener("load", handleScript);
      script.addEventListener("error", handleScript);
    }
   
   script.addEventListener("load", handleScript);
   script.addEventListener("error", handleScript);
   
    }, [url]);
    
    
    return (

        <React.Fragment>

        <Container className="containerTextHomepage">
            
       
        <div id="ma_w_wrapper" className="meilleursAgents ma-widget-feedback ma-widget-feedback-v2 ma-widget-feedback-type1 ma-widget-feedback--small" data-version="v2" data-script-url="https://widgets.meilleursagents.com/feedback.js"><div id="ma_w_content53114"></div><a id="ma_w_feedback_licence" className="ma-widget-feedback__logo" target="_blank" href="https://www.meilleursagents.com/agence-immobiliere/agence-aib-immobilier-53114"><img src="https://www.meilleursagents.com/my/realtor/static/media/widget-logo.svg" width="144" alt="AIB IMMOBILIER"/></a></div>

        {/* <ImgAibstyled src={ImgAibFB} /> */}
        {/* <ImgAibstyled src={ImgAibInsta} /> */}
        
        </Container>
        </React.Fragment>


    )
}

export default Logoaccueil;