import React, {useState} from "react";
import { Container } from 'react-bootstrap';






const Partenaire = (props) => {
  
const [showButton, setshowButton] = useState(false)

const toogleButton = ()=>{
  setshowButton(true)
  
}
const toogleButton1 = ()=>{
  setshowButton(false)
}

  return (
  
    <Container>
    <div className="rotate">
     <div >
    
    <a href={props.link} target="_blank" rel="noopener noreferrer"> 
    <img className="imgPartenaire " src={props.pathimg} alt="" onMouseOver={toogleButton} onMouseOut={toogleButton1}/>
     <div>
      {showButton ? <button className="visiter btn btn-primary " onMouseEnter={toogleButton} onMouseLeave={toogleButton1}>Visiter le site</button> : ''}
    
    </div>
    </a>
    
    </div> 


    </div>
   
    </Container>
  )
}

export default Partenaire;