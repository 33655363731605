import React, { useEffect, useState } from "react";
import $ from 'jquery';
import axios from "axios";
import { InputGroup, FormControl, Row, Col , Container} from 'react-bootstrap'
import Gotodisplay from "./gotodisplay";


const displayTaux = (taux)=> {
  return "Taux : "+ taux  +" (*)" 
}  

const Outils = () => {
  const [gotop, setGotop] = useState(true);
  Gotodisplay(gotop,setGotop)
  const [dataForCalculette, setdataForCalculette] = useState([])

  const [currentTaux, setCurrentTaux] = useState(0)
  const [currentDureeEmprunt, setCurrentDureeEmprunt] = useState(0)
  const [currentMontantEmpruntee, setCurrentMontantEmpruntee] = useState("Montant emprunté")
  const [currentMensualitees, setMensualitees] = useState("Mensualités")
  const [montantDuBien, setMontantDuBien] = useState("Montant du bien")
  const [apportInitial, setApportInitial] = useState("Apport Initial")

  const [montantDuBienError, setMontantDuBienError] = useState("")
  const [apportInitialError, setApportInitialError] = useState("")

   /* champ de bit pour validation du formulaire  ==> 0b111111 = 63 dec*/
   const [formValid, setFormValid] = useState(0);

  
   

  

  useEffect(() => {

    $(".mnu").removeClass("menuSelected")
    $("#outilsLink").addClass("menuSelected")

    const fetchData = async () => {
      const result = await axios(
        //  'http://localhost:3001/calculette',
        'https://www.aibimmobilier.fr:444/calculette',
      );

      let dataFromDb = []
      result.data.map((item, index) => (
        dataFromDb.push({ dureeemprunt: item.dureeemprunt, taux: item.taux })
      ));
      setdataForCalculette(dataFromDb)
      setCurrentDureeEmprunt(result.data[0].dureeemprunt)
      setCurrentTaux(result.data[0].taux)
      
    };

    fetchData();
    


  }, [])


  const handleMontantDuBien = (e) =>{
    let valfield = e.target.value
    let champBit = formValid;
    let numbers = /^[-+]?[0-9]+$/;
    let valid = false;
    let valid1 = false;
    (valfield.length >= 3) ? valid  = true : valid  = false;
    (valfield.match(numbers)) ? valid1  = true : valid1  = false;
    if (valid && valid1) {
      setMontantDuBien(valfield)
      setMontantDuBienError("")
      champBit = champBit | (1 << 0)
      setFormValid(champBit)
    }else{
      setMontantDuBien("Montant du bien")
      setMontantDuBienError("Montant invalide")
      champBit = champBit & ~(1 << 0)
      setFormValid(champBit)
    }
  }
  const handleApportInitial = (e) =>{
    let valfield = e.target.value
    let champBit = formValid;
    let numbers = /^[-+]?[0-9]+$/;
    let valid = false;
    let valid1 = false;
    (valfield.length >= 3) ? valid  = true : valid  = false;
    (valfield.match(numbers)) ? valid1  = true : valid1  = false;
    if (valid && valid1) {
      setApportInitial(valfield)
      setApportInitialError("")
      champBit = champBit | (1 << 1)
      setFormValid(champBit)
    }else{
      setApportInitial("Apport initial")
      setApportInitialError("Apport invalide")
      champBit = champBit & ~(1 << 1)
      setFormValid(champBit)
    }
    
  }
  const handleCalculate = (e) =>{
    if (formValid === 3){
      let bien, apport, duree, tau, ta, tm, mens, s_emprunt, s_mens;
      bien = parseInt(montantDuBien)
      apport = parseInt(apportInitial)
      duree = parseInt(currentDureeEmprunt)
      tau = parseFloat(currentTaux)

    
    ta = tau / 100;
    tm = ta / 12;
    mens = (bien - apport) * (tm) / (1 - Math.pow(1 / (1 + tm), 12 * duree));
    mens = Math.round(mens);

    s_mens = mens.toString();
    s_emprunt = (bien - apport).toString();
   // s_cout = (mens * 12 * duree - bien + apport).toString();

    setCurrentMontantEmpruntee(s_emprunt)
    setMensualitees(s_mens)
    }  
  }


  const handleOnChangeDureeEmprunt = (e)=>{
    
    let valDureeEmprunt = e.target.value
    setCurrentDureeEmprunt(valDureeEmprunt)
    let ret
    ret = dataForCalculette.filter(item => item.dureeemprunt === parseInt(valDureeEmprunt, 10)).map(item=>item.taux).toString();
    ret = Number(ret)
    setCurrentTaux(ret)

  }


  return (
    <React.Fragment>
    <Container className="containerAib">
      <Row>
        <div className="col-md-12 ">
          <ol className="breadcrumb">
            <li className="active">&nbsp;&nbsp;Outils Pratiques</li>
          </ol>
        </div>
      </Row>

      <div className="row divaibblock">
        <div className="col-md-10 col-md-offset-1 ">
          <Row>
          
          <div className="txttitre  col-md-10 col-md-offset-1 ">
            Calculette AIB
          </div>
          </Row>
          <Row>
          <div className="col-md-11 col-md-offset-1 ">
            La calculette ci-dessous vous permet d'estimer le montant de vos mensualités. Saisissez le montant du bien ainsi que votre apport et la durée estimée du prêt immobilier.
         </div>
            </Row>
            
            <Row>



              
              <Col  md={{ span: 6, offset: 2 }}>
              <label className="control-label ">Montant du bien</label>
                  <InputGroup className="md-4">
                    <FormControl
                      placeholder={montantDuBien}
                      aria-label="Montant du bien"
                      aria-describedby="basic-addon1"
                      onChange={handleMontantDuBien}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon1">€</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <div className="help-block with-errors">&nbsp;{montantDuBienError}</div>
              </Col>
              </Row>
              <Row>
              <Col  md={{ span: 6, offset: 2 }}>
              <label className="control-label ">Apport initial</label>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder={apportInitial}
                      aria-label="Apport initial"
                      aria-describedby="basic-addon2"
                      onChange={handleApportInitial}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2">€</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <div className="help-block with-errors">&nbsp;{apportInitialError}</div>
                  </Col>
              </Row>
        
              <Row>

              <Col  md={{ span: 6, offset: 2 }}>
              <div className="form-group">
                <label className="control-label ">Durée de l'emprunt (années)</label>
                <div className="input-group">
                  <select id="dureeemprunt" type="text" className="form-control" name="dureeemprunt" onChange={handleOnChangeDureeEmprunt} >
                    {dataForCalculette.map((item, index) => (
                      <option key={index}>{item.dureeemprunt}</option>
                    ))}
                  </select>
                </div>

              </div>
                  </Col>
              </Row>

              <Row>
              <Col  md={{ span: 4, offset: 2 }}>
                  <InputGroup className="mb-3">
                  
                    <FormControl
                      
                      placeholder={displayTaux(currentTaux)}
                      aria-label="Taux"
                      aria-describedby="basic-addon3"
                      readOnly
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon3">%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  </Col>
              </Row>
              <Row>
              <Col  md={{ span: 8, offset: 2 }}>
                <div >* Taux moyen du marché calculé en fonction de la durée</div>
                </Col>
              </Row>
              <Row>
              <Col  md={{ span: 6, offset: 2 }}>
              
              <button className="btn btn-primary buttonInfo" onClick={handleCalculate}>Envoyer</button><span className="errorval" id="errorcalcul"></span>

                </Col>
              </Row>
            
              <Row>
              <Col  md={{ span: 6, offset: 2 }}>
              <label className="control-label ">Montant emprunté</label>
                  <InputGroup className="mb-3">
                  
                    <FormControl
                      placeholder={currentMontantEmpruntee}
                      aria-label="Montant emprunté"
                      aria-describedby="basic-addon4"
                      readOnly
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon4">€</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  </Col>
              </Row>
              <Row>
              <Col  md={{ span: 6, offset: 2 }}>
              <label className="control-label ">Mensualités</label>
                  <InputGroup className="mb-3">

                    <FormControl
                      placeholder={currentMensualitees}
                      aria-label="Mensualités"
                      aria-describedby="basic-addon5"
                      readOnly
                    />
                    
                  </InputGroup>
                  </Col>
              </Row>
     </div>
      </div>

      </Container>
    </React.Fragment>
  )
}

export default Outils;