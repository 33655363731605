import React, { useState, useContext} from 'react';
import 'react-animated-slider/build/horizontal.css';
import '../css/detailBien.scss';
import { useHistory, Redirect } from "react-router-dom";
import contextannonces from './contextannonces'
import DetailBien from './detailBien'
import DetailLocation from './detailLocation'
import Nobien from './nobien'








const Bienunique = (props) => {

    const { listeAnnonces } = useContext(contextannonces);
    const [sel, setsel]  = useState(props.sel)
    const [masel, setmasel]  = useState(listeAnnonces)
    const [venteloc, setVenteloc] = useState(props.venteloc)




   
const selectiondubien = () =>{
    const objSel =   (listeAnnonces.filter(
        function (bien) {
            return bien.aff_num === sel
        }
    ))

    if (objSel.length >0){    
    return (
         objSel.map((item, index) => ( goto(item))) 
        //(objSel.length > 0) ? objSel.map((item, index) => ( goto(item))) : <Redirect to='/'/>
    )
    }
    if (objSel.length ===0){  
        let  typedebien
        (venteloc === "loc") ? typedebien ="location" : typedebien = "vente"

        return (
            <Nobien typedebien={typedebien}/>
        )
        
    }

}
    


let history  = useHistory()
const goto = (item)=>{

    
    if (venteloc === "vente"){
        history.push('/DetailBien/' + sel)
        DetailBien.defaultProps = { sel: item }

    }

    if (venteloc === "loc"){
        history.push('/DetailLocation/' + sel)
        DetailLocation.defaultProps = { sel: item }

    }
   
}
    

  return (
      <React.Fragment>
     {selectiondubien()}
      </React.Fragment>
  );
}

export default Bienunique;

