import React,  { useContext, useEffect, useState } from "react";
import { Row, Col, Container} from 'react-bootstrap';
import BandeauVitrine from "./bandeauVitrine";
import Texthomepage from "./texthomepage";
// import Texthomepage from "./texthomepage_old";
import GoogleStat, { TrackGoogleAnalyticsEvent } from "./googleAnalytics";


import Logoaccueil from "./logoaccueil";



import contextannonces from './contextannonces'
import $ from 'jquery'
import Gotodisplay from "./gotodisplay";





const Home = () =>{
  const [gotop, setGotop] = useState(true);
  Gotodisplay(gotop,setGotop,0)

  const {listDesImages1}  = useContext(contextannonces);
  const {listDesImages2}  = useContext(contextannonces);
  const {listDesImages3}  = useContext(contextannonces);


  


  useEffect(() => {
    $(".mnu").removeClass("menuSelected") 
    TrackGoogleAnalyticsEvent(
      "HomeCategory",
      "Home",
      window.location.pathname
    )
    
  })   





  return (
    
    <Container className=" containerAib">
     <Row><Col md={{ span: 12 }} className="titrevitrineBandeau d-none d-md-block">Quelques uns de nos biens</Col></Row>
 


    
      <BandeauVitrine  rangePhotos={[listDesImages1,listDesImages2,listDesImages3]}/>

    

    <Row><Col md={{ span: 3, offset: 1   }}>
    <Logoaccueil/>
      </Col>
      <Col md={{ span: 8}}>
      <Texthomepage/>
        </Col>
      </Row>
  
    </Container>
    
  )
}

export default Home;