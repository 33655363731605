import {useState} from 'react'


const Modalpopuplogique = ()=>{

    const [affiche, setAffiche] = useState(false);

    function toggle(){
        setAffiche(!affiche)
    }

    return {
        affiche, 
        toggle
}

}

export default Modalpopuplogique;
