import React, { useEffect, useState } from "react";

import GoogleStat, {
  InitializeGoogleAnalytics,
} from "./components/googleAnalytics";
import "bootstrap/dist/css/bootstrap.min.css";
//import logo from './logo.svg';
//import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import "./css/App.scss";
import "./css/application.scss";
import Home from "./components/home";
import Agence from "./components/agence";
import Biens from "./components/biens";
import Louer from "./components/louer";
import Vendre from "./components/vendre";
import Contact from "./components/contact";
import Outils from "./components/outils";
import Partenaires from "./components/partenaires";
import Bandeauaccueil from "./components/bandeauaccueil";
import LogoAib from "./components/logoAib";
import Footer from "./components/footer";
import DetailBien from "./components/detailBien";
import DetailLocation from "./components/detailLocation";
import Mentionslegales from "./components/mentionslegales";
import Honoraires from "./components/honoraires";
import Bienunique from "./components/bienunique";
import Biensvendus from "./components/biensvendus";
import Listofqrcode from "./components/listofqrcode";

import Headerright from "./components/headerright";
import { Layout } from "./components/layout";
import { NavigationBar } from "./components/navigationBar";
import contextannonces from "./components/contextannonces";
import axios from "axios";
import Scrollbuttonsct from "./components/scrollbutton";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { Cookies } from "react-cookie";
import { faSortNumericDownAlt } from "@fortawesome/free-solid-svg-icons";

const App = (props) => {
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [malistedeBien, setMalistedeBien] = useState([]);
  const [malistedeBienVendus, setMalistedeBienVendus] = useState([]);
  const [updateDateTimeSite, setUpdateDateTimeSite] = useState("");

  // const [maliste, setMaliste] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  const [duringloading, setduringloading] = useState("load");

  const [listImg1, setListImg1] = useState([]);
  const [listImg2, setListImg2] = useState([]);
  const [listImg3, setListImg3] = useState([]);

  const contextannoncesValue = {
    listeAnnonces: malistedeBien,
    listeAnnoncesVendus: malistedeBienVendus,
    listDesImages1: listImg1,
    listDesImages2: listImg2,
    listDesImages3: listImg3,
    updateDateTimeSite: updateDateTimeSite,
    updateListeAnnonces: setMalistedeBien,
    updateListeBiensVendus: setMalistedeBienVendus,
    updateDateTimeSiteContext: setUpdateDateTimeSite,
  };
  
  useEffect(() => {
    
    const fetchData = async () => {
      
      const result = await axios(
        //  'http://localhost:3001/bien',
        "https://www.aibimmobilier.fr:444/bien"
      );
      // setMalistedeBien(result.data);

      let tmp;

      tmp = result.data.filter(function (res) {
        return (
          res.statut.substring(1) !== "Vendu" &&
          res.statut.substring(1) !== "Archive" &&
          res.statut.substring(1) !== "Compromis" &&
          res.statut.substring(1) !== "Sous Compromis"
        );
      });
      setMalistedeBien(tmp);

      let vendus;
      vendus = result.data.filter(function (res) {
        let tmp;
        if (res.vente !== undefined) {
          if (res.vente.vente_signature_date_acte === null) {
            let now = new Date().toJSON();
            res.vente.vente_signature_date_acte = now;
          }
        }
        return (
          res.statut.substring(1) === "Vendu" ||
          res.statut.substring(1) === "Archive" ||
          res.statut.substring(1) === "Compromis" ||
          res.statut.substring(1) === "Sous Compromis"
        );
      });
      setMalistedeBienVendus(vendus);

      let venteOnly;
      venteOnly = tmp.filter(function (bienAvendre) {
        // return (bienAvendre.vente_id >= 1 && bienAvendre.statut.substring(1) !== 'Vendu');
        return bienAvendre.vente_id >= 1;
      });

      let imgArray1 = listImg1;
      let imgArray2 = listImg2;
      let imgArray3 = listImg3;
      let moitieListeAnnonce = venteOnly.length / 3;

      venteOnly.map((item, index) => {
        if (index <= moitieListeAnnonce)
          imgArray1.push([item.intitule.fr, item.imagesbien[0].img, item]);
        if (index > moitieListeAnnonce && index <= moitieListeAnnonce * 2)
          imgArray2.push([item.intitule.fr, item.imagesbien[0].img, item]);
        if (index > moitieListeAnnonce * 2 && index < moitieListeAnnonce * 3)
          imgArray3.push([item.intitule.fr, item.imagesbien[0].img, item]);
      });
      
      
      setListImg1(imgArray1);
      setListImg2(imgArray2);
      setListImg3(imgArray3);
      setIsLoading(false);
      setduringloading("loadfinish");
    
    };

    const fetchDatetimeupdate = async () => {
      const resultFetchDatetimeupdate = await axios(
        "https://www.aibimmobilier.fr:444/datetimeupdate"
      );
      let tmp;
      // tmp = resultFetchDatetimeupdate.data.filter(function(res){
      //   return (res.exportUpdateDateTime)
      // })

      tmp = resultFetchDatetimeupdate.data.map((item, index) => {
        return item.exportUpdateDateTime;
      });

      const datetimeupdateSite = new Date(tmp[0]);
      let year = datetimeupdateSite.getFullYear();

      let month = datetimeupdateSite.getMonth();
      month = ("0" + (month + 1)).slice(-2);

      let date = datetimeupdateSite.getDate();
      date = ("0" + date).slice(-2);

      let hour = datetimeupdateSite.getHours();
      hour = ("0" + hour).slice(-2);

      let minute = datetimeupdateSite.getMinutes();
      minute = ("0" + minute).slice(-2);

      let second = datetimeupdateSite.getSeconds();
      second = ("0" + second).slice(-2);

      const datetimeUpdateSiteString = `${date}/${month}/${year} à ${hour}:${minute}`;

      // a = datetimeupdateSite.format("YYYY-MM-DDTHH:mm:ss.SSSZZ")
      // .format("YYYY-MM-DDTHH:mm:ss.SSSZZ"))
      // console.log(datetimeUpdateSiteString)

      // 2023-03-05T15:53:01.000Z
      setUpdateDateTimeSite(datetimeUpdateSiteString);
    };

    

      fetchData();
      fetchDatetimeupdate();
    

    const isConsent = getCookieConsentValue();

    if (isConsent === "true") {
      handleAccept();
    } else {
      handleDecline();
    }

    // InitializeGoogleAnalytics();
  }, []);

  const fn_Isloading = () => {
    if (isLoading)
      return (
        <Container className="overlaymodale">
          <button className="btn btn-primary" type="button" disabled>
            <span
              className="spinner-border spinner-border-sg"
              role="status"
              aria-hidden="true"
            ></span>
            &nbsp; &nbsp;Chargement en cours...
          </button>
        </Container>
      );
  };

  const directroutebienvente = (e) => {
    let monSlug = e.match.params.slug;

    if (e.history.action === "PUSH") {
      return <Route component={DetailBien} />;
    } else {
      return (
        <Route
          render={(props) => (
            <Bienunique {...props} sel={monSlug} venteloc="vente" />
          )}
        />
      );
    }
  };

  const directroutebienlocation = (e) => {
    let monSlug = e.match.params.slug;

    if (e.history.action === "PUSH") {
      return <Route component={DetailLocation} />;
    } else {
      return (
        <Route
          render={(props) => (
            <Bienunique {...props} sel={monSlug} venteloc="loc" />
          )}
        />
      );
    }
  };
  const handleAccept = () => {
    setShowCookieConsent(false);
    InitializeGoogleAnalytics();
    // code to set cookie preference
    // ReactGA.initialize("G-QGP6L30YLK");
    // <GoogleStat/>

    // Cookies.set("aibimmobilierCookie", true)
  };
  const handleDecline = () => {
    setShowCookieConsent(true);
    const cookies = new Cookies();

    let gaCookies = cookies.getAll();

    Object.entries(gaCookies).map(
      ([key, value]) => (
        cookies.remove(key, { domain: ".aibimmobilier.fr" }),
        cookies.remove(key, { domain: ".aibimmobilier.com" }),
        cookies.remove(key, { domain: "www.aibimmobilier.fr" }),
        cookies.remove(key, { domain: "www.aibimmobilier.com" })
      )
    );

    // cookies.remove("_ga", {domain :'.aibimmobilier.fr'});
    // cookies.remove("_ga_ZDMKV30PCH", {domain :'.aibimmobilier.fr'});
    // cookies.remove("_ga", {domain :'.aibimmobilier.com'});
    // cookies.remove("_ga_ZDMKV30PCH", {domain :'.aibimmobilier.com'});

    // cookies.remove("_ga");
    // cookies.remove("_ga_ZDMKV30PCH");
    cookies.remove("CookieConsent");
    // Cookies.remove("_ga");
    // Cookies.remove("_gat");
    // Cookies.remove("_gid");
    // Cookies.remove("CookieConsent");
    // resetCookieConsentValue()

    // setDeclineCookie(true)
    // code to set cookie preference
  };
  return (
    <contextannonces.Provider value={contextannoncesValue}>
      <div>
        {showCookieConsent && (
          <CookieConsent
            location="bottom"
            buttonText="ACCEPTER"
            declineButtonText="REFUSER"
            flipButtons
            // cookieName="aibimmobilierCookie"
            style={{ background: "#ac0000 " }}
            buttonStyle={{ color: "#fff", background: "#222" }}
            declineButtonStyle={{ color: "#fff", background: "#222" }}
            sameSite="lax"
            expires={150}
            onAccept={handleAccept}
            enableDeclineButton
            onDecline={handleDecline}
          >
            <h5>
              <b>Aib Immobilier respecte votre vie privée.</b>
            </h5>
            <h6>
              En cliquant sur "ACCEPTER", vous acceptez l'utilisation de cookies
              et autres traceurs servant à mesurer l'audience. Les cookies sont
              des données qui sont téléchargées ou stockées sur votre ordinateur
              ou sur tout autre appareil. Vous pouvez revenir sur votre choix à
              tout moment en cliquant sur la page mentions légales et le bouton
              "Désactiver vos cookies".
            </h6>
          </CookieConsent>
        )}
        {/* rest of the app */}
      </div>

      {fn_Isloading()}
      <React.Fragment>
        {/* <div className="container bgmain " duringloading> */}
        <div className="container bgmain ">
          <Layout>
            <Router forceRefresh={false}>
              <Row>
                <Col md={6}>
                  <LogoAib />
                </Col>
                <Col md={{ offset: 1 }}>
                  <Headerright />
                </Col>
              </Row>
              <Row className="d-none d-md-block">
                <Bandeauaccueil />
              </Row>

              <NavigationBar />
              <Scrollbuttonsct />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/agence" component={Agence} />
                <Route exact path="/acheter" component={Biens} />
                <Route
                  path="/DetailBien/:slug"
                  component={directroutebienvente}
                />
                <Route
                  exact
                  path="/DetailLocation/:slug"
                  component={directroutebienlocation}
                />
                <Route exact path="/louer" component={Louer} />
                <Route exact path="/vendre" component={Vendre} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/contact/:slug" component={Contact} />
                <Route exact path="/outils" component={Outils} />
                <Route
                  exact
                  path="/mentionslegales"
                  component={Mentionslegales}
                />
                <Route exact path="/honoraires" component={Honoraires} />

                <Route exact path="/partenaires" component={Partenaires} />
                <Route exact path="/biensvendus" component={Biensvendus} />
                <Route exact path="/qrcodes" component={Listofqrcode} />

                <Route component={Home} />
                <Route path="*" />
              </Switch>
              <div>
                <Footer />
              </div>
            </Router>
          </Layout>
        </div>
      </React.Fragment>
    </contextannonces.Provider>
  );
};

//}

export default App;
