import React  from 'react';



export default React.createContext({
    listeAnnonces: [],
    listeAnnoncesVendus: [],
    listDesImages1: [],
    listDesImages2: [],
    listDesImages3: [],
    updateDateTimeSite: [],
    updateListeAnnonces: name =>   {} ,
    updateListeBiensVendus: name =>   {} ,
    updateDateTimeSiteContext: name =>   {} ,

});