import React from "react";

import ImgAib from '../images/AibLogo2024.png'


// import ImgAib from '../images/AibLogoNew.png'
// import ImgAib from '../images/AibLogoNoel.png'




import styled from 'styled-components'
import { useHistory } from "react-router-dom";



const ImgAibstyled = styled.img`
margin-top: 20px;
alt: AIB;
// height: 180px;  // commenté pour Noel
height: 160px;
`

const LogoAib = () =>{
  let history = useHistory();
  

  const clicLogo = ()=>{
    history.push('/')
 }
  
  return (
    <ImgAibstyled src={ImgAib} onClick={clicLogo} />
  )
}

export default LogoAib;