import React from 'react'
import { Row, Col} from 'react-bootstrap';



const Modalpopup = ({affiche, cache, email}) => affiche ? (

    <React.Fragment>
      
        
        <div className="overlay"/>
        
        <div className="wrapper">
        <Row>
            <Col md={12}>
            <p className="addpadding">Un email vient de vous être envoyé à l'adresse<br/><b>{email}</b></p>
            </Col>
            </Row>
            
            
            <Row>
            <Col md={12}>
                <button className="btn btn-primary buttonInfo" onClick={cache} ><span>OK</span></button>
            </Col>
            </Row>

        </div>
       

    </React.Fragment>

): null;



export default Modalpopup;