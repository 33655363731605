import { data } from "jquery";
import React, { useState, useContext, useEffect } from "react";
import { Container, Row } from 'react-bootstrap';
import axios from "axios";
// import * as files from '../images/listOfQRCode/*';
// import aa from '../images/listOfQRCode/qrcode_location_230307.png';









// // const a = process.env.PUBLIC_URL 



// // const images = require.context(process.env.PUBLIC_URL +'listOfQRCode', false, /\.png$/);

const getFileName =(file) => {
  let rettmp = file.split('/');
  let tmpFileName = rettmp[rettmp.length - 1];
  const regexVenteLocation =  /.*(vente|location).*/i;
  let ventelocationMatch = tmpFileName.match(regexVenteLocation);
  let ventelocation = "";
  if (ventelocationMatch){
    if (ventelocationMatch[1] === "vente"){
      ventelocation = "Vente";
    }
    if (ventelocationMatch[1] === "location"){
      ventelocation = "Location";
    } 
  }

  const regexAnnonceNumber =  /.*(_)(.*)(.png)/i;
  let annonceMatch = tmpFileName.match(regexAnnonceNumber);
  let annonce = "";
  if (annonceMatch){
    annonce = annonceMatch[2];
  }

    // let ret= [ventelocation,"aa"];
    let ret= tmpFileName;
  return ([ventelocation, annonce]);
}


const Listofqrcode = (props) => {

    
    const [listofQrCode, setListofQrCode] = useState([]);
    useEffect(() => {

        const fetchQRcodes = async () => {
            const resultFetchQRcode = await axios(
              "https://www.aibimmobilier.fr:444/qrcode"
            );
            let tmp;
              
            tmp = resultFetchQRcode.data.map((item, index) => {
              return('https://www.aibimmobilier.fr/qrcodes/'+item);
            });
            
            setListofQrCode(tmp)  
          };  
      
            
          fetchQRcodes();

    })


  

return (

<div className="row">
  
    {listofQrCode.map((item, index) => (
        <div  key={index} className="col-md-4 ">
          <div className="card divaibblock1 ">
           
            <img key={index} width="200" src={item} alt={item}  className="card-body "/> <br/>
            <span className=" titreaibmsgQRCode">{getFileName(item)[0]} numéro {getFileName(item)[1]} 

            </span>
            
             
             
             </div>
             </div>
    ))}



    </div>  


    )
}





export default Listofqrcode;