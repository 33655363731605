import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useParams, Redirect } from "react-router-dom";
//import {Slide} from 'react-slideshow-image';
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

import { Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTags,
  faPaperclip,
  faListUl,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";

import reactStringReplace from "react-string-replace";
import "../css/detailBien.scss";
import { useHistory } from "react-router-dom";
import Dpe from "./dpe";
import Ges from "./ges";
import ZoomImg from "./zoomImg";
import ZoomImgLogique from "./zoomImgLogique";

// const formatPrix = (prix) =>{
//     let prix_str = prix.toString();
//     return prix_str.substr(0, prix_str.length - 3) + "  " + prix_str.substr(prix_str.length - 3) + " €";
// }

const formatPrix = (prix, charge) => {
  let prix_str = (prix + charge).toString();

  return (
    prix_str.substr(0, prix_str.length - 3) +
    "  " +
    prix_str.substr(prix_str.length - 3) +
    " €"
  );
};

const dateDPEGESPivot = new Date(2021, 6, 1);

const before2021 = (dateDPEGES) => {
  let dateToCheckArray = dateDPEGES.split("/");

  let yearToCheck = dateToCheckArray[2];
  let monthToCheck = dateToCheckArray[1];
  let dayToCheck = dateToCheckArray[0];
  let dateToCheck = new Date(yearToCheck, monthToCheck - 1, dayToCheck);

  if (dateToCheck < dateDPEGESPivot) {
    return (
      <span>
        Diagnostic réalisé avant le 1<sup>er</sup> juillet 2021
      </span>
    );
  } else {
    //return <span>Diagnostic réalisé après le 1<sup>er</sup> juillet 2021</span>;
    return <span></span>;
  }
};

let estimationAnnuelStandardExpDpe = "";
let chargeMin = "";
let chargeMax = "";

const coutAnnuelEnergie = (dateDPEGES) => {
  let dateToCheckArray = dateDPEGES.split("/");

  let yearToCheck = dateToCheckArray[2];
  let monthToCheck = dateToCheckArray[1];
  let dayToCheck = dateToCheckArray[0];
  let dateToCheck = new Date(yearToCheck, monthToCheck - 1, dayToCheck);

  if (dateToCheck < dateDPEGESPivot) {
    return (
      <span>
        <u>Estimation des coûts annuels d'énergie du logement :</u>{" "}
        <b>{estimationAnnuelStandardExpDpe}€</b>
      </span>
    );
  } else {
    // return <span>Diagnostic réalisé après le 1<sup>er</sup> juillet 2021</span>;
    return (
      <span>
        <u>Estimation des coûts annuels d'énergie du logement</u>
        <br />
        {estimationCoutNul()}
        {/* entre <b>{chargeMin}€</b> et <b>{chargeMax}€</b> */}
      </span>
    );
  }
};



const estimationCoutNul = () => {
  let ret = ""
  if (chargeMin>0 && chargeMax >0){
    ret = <span className="red-error">entre {chargeMin} € et {chargeMax} €</span>
    
  }else{
    ret = <><b className="red-error">Non renseigné</b></>
  } 


  return(
    ret
  )
}


const DetailLocation = (props) => {
  const [photo, setphoto] = useState("");
  const { affiche, toggle } = ZoomImgLogique();

  const ZoomPhoto = (item) => {
    setphoto(item);
    toggle();
  };

  let history = useHistory();
  const clicContact = (slug) => {
    history.push("/contact/" + slug);
  };

  const [sel, setSel] = useState(props.sel);
  const [selRent, setSelRent] = useState(props.rent);
    const [selIndex, setSelIndex] = useState(props.sel.aff_id);


  const [selVisiteVirtuelle, setVisiteVirtuelle] = useState(
    props.sel.visite_virtuelle.split("\n")[1]
  );
  const [selVisiteVirtuelleTmp, setVisiteVirtuelleTmp] = useState(
    selVisiteVirtuelle.split("/")
  );
  const [selVisiteVirtuelleItem, setVisiteVirtuelleItem] = useState(
    "https://embed.ricoh360.com/" +
      selVisiteVirtuelleTmp[selVisiteVirtuelleTmp.length - 1]
  );



  let { slug } = useParams();
  useEffect(() => {
    $(".mnu").removeClass("menuSelected") 
    if (props.rent === "Yes"){
        $("#biensVendusLink").addClass("menuSelected") 
    }else{
        $("#locLink").addClass("menuSelected")         
    }
  });

  const fncAddHrefLinkIfNeeded = (txt) => {
    
    const ret = [];
    const ret1 = [];

    ret.push(txt.split(".")[0]);
    let foundPoint = txt.match(/.*(\.\s+).*/gi);

    for (let step = 0; step < foundPoint.length; ++step) {
      let a = foundPoint[step].replace(
        /\.\s+/gi,
        "<p key=msg" +
          step +
          '><FontAwesomeIcon icon={faTags} className="couleurAIB" /></p>'
      );
      ret.push(a);
    }

    for (let step = 0; step < ret.length; step++) {
      let flag = 1;
      if (ret[step].includes("http://")) {
        let regexHttp = /(http:\/\/[^\s]+)/g;
        let linkHttp = ret[step].match(regexHttp)[0];
        let tmp = ret[step].replace(
          /http:\/\/\S+/gi,
          '<a href="' +
            linkHttp +
            '" target="_blank" rel="noopener noreferrer">' +
            linkHttp +
            "</a>"
        );
        ret1.push(<span dangerouslySetInnerHTML={{ __html: tmp }}></span>);
        flag = 0;
      }
      if (ret[step].includes("https://")) {
        let regexHttps = /(https:\/\/[^\s]+)/g;
        let linkHttps = ret[step].match(regexHttps)[0];
        let tmp = ret[step].replace(
          /https:\/\/\S+/gi,
          '<a href="' +
            linkHttps +
            '" target="_blank" rel="noopener noreferrer">' +
            linkHttps +
            "</a>"
        );
        ret1.push(<span dangerouslySetInnerHTML={{ __html: tmp }}></span>);
        flag = 0;
      }
      if (flag == 1) {
        ret1.push(
          <span dangerouslySetInnerHTML={{ __html: ret[step] }}></span>
        );
      }
    }

    return ret1;
  };

  if (props.sel === undefined) {
    return <Redirect to="/" />;
  }

  const carouselPhotos = props.sel.imagesbien;

  const textToRepace = props.sel.commentaire.fr;
  const addHrefLinkIfNeeded = fncAddHrefLinkIfNeeded(textToRepace);

  let newDescriptif = addHrefLinkIfNeeded;

  // newDescriptif = reactStringReplace(textToRepace, /\.\s+/gm, (match, i) => (
  //   <p key={i}>
  //     <FontAwesomeIcon icon={faTags} className="couleurAIB" /> {match}
  //   </p>
  // ));

  let infosComplementairesArray = [];
  let lettreDPE = [];
  let lettreGES = [];
  let dateDPEGES = "";
  const fnc_infosComplementaires = (val, txtVal, typeval, txtafter) => {
    switch (typeval) {
      case "int":
        if (val > 0 && val !== 0)
          infosComplementairesArray.push([txtVal, val + txtafter]);
        break;
      case "txt":
        if (val !== "" && val !== "0")
          infosComplementairesArray.push([txtVal, val + txtafter]);
        break;
      default:
        break;
    }
  };

  if (props.sel.maison !== undefined) {
    fnc_infosComplementaires(
      props.sel.maison.ANNEE_CONSTRUCTION,
      "Année de construction",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.NBRE_PIECES,
      "Nombre de pièces",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.SURFACE_HABITABLE,
      "Surface habitable",
      "int",
      " m²"
    );
    fnc_infosComplementaires(
      props.sel.maison.SURFACE_SEJOUR,
      "Surface du séjour",
      "int",
      " m²"
    );

    fnc_infosComplementaires(
      props.sel.maison.NBRE_CHAMBRES,
      "Nombre de chambres",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.SURFACE_TERRAIN,
      "Surface du terrain",
      "int",
      " m²"
    );
    fnc_infosComplementaires(
      props.sel.maison.NBRE_NIVEAUX,
      "Nombre de niveaux",
      "txt",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.NBRE_SALLE_BAIN,
      "Nombre de salle de bain",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.NBRE_WC,
      "Nombre de WC",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.NBRE_SALLE_EAU,
      "Nombre de salle d'eau",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.NBRE_ETAGE,
      "Nombre d'étage",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.CHAMBRE_RDC,
      "Nombre de chambre au RDC",
      "int",
      ""
    );
    fnc_infosComplementaires(props.sel.maison.GRENIER, "Grenier", "int", "");
    fnc_infosComplementaires(
      props.sel.maison.ETAT_GENERAL,
      "Etat général",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.FENETRE,
      "Type de fenêtres",
      "txt",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.MODE_CHAUFFAGE,
      "Mode de chauffage",
      "txt",
      ""
    );
    fnc_infosComplementaires(
      props.sel.maison.COUP_COEUR,
      "Coup de Coeur",
      "int",
      ""
    );

    lettreDPE = [
      props.sel.maison.CONSOMMATIONENERGETIQUE,
      props.sel.maison.CONSO_ANNUEL_ENERGIE,
    ];
    lettreGES = [props.sel.maison.GAZEFFETDESERRE, props.sel.maison.VALEUR_GES];
    dateDPEGES = sel.maison.DATE_DPE;
    estimationAnnuelStandardExpDpe = sel.maison.ESTIMATION_ANNUEL_STANDARD_EX_DPE;
    chargeMin = sel.maison.CHARGE_ENERGIE_MIN;
    chargeMax = sel.maison.CHARGE_ENERGIE_MAX;
  }
  if (props.sel.terrain !== undefined) {
    fnc_infosComplementaires(
      props.sel.terrain.CONSTRUCTION_LIBRE,
      "Construction libre",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.terrain.COUP_COEUR,
      "Coup de Coeur",
      "int",
      ""
    );
    lettreDPE = ["", ""];
    lettreGES = ["", ""];
    dateDPEGES = "";
  }

  if (props.sel.appartement !== undefined) {
    fnc_infosComplementaires(
      props.sel.appartement.ANNEE_CONSTRUCTION,
      "Année de construction",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.NBRE_PIECES,
      "Nombre de pièces",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.SURFACE_HABITABLE,
      "Surface habitable",
      "int",
      " m²"
    );
    fnc_infosComplementaires(
      props.sel.appartement.SURFACE_SEJOUR,
      "Surface du séjour",
      "int",
      " m²"
    );
    fnc_infosComplementaires(
      props.sel.appartement.NBRE_CHAMBRES,
      "Nombre de chambres",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.SURFACE_TERRAIN,
      "Surface du terrain",
      "int",
      " m²"
    );
    fnc_infosComplementaires(
      props.sel.appartement.NBRE_NIVEAUX,
      "Nombre de niveaux",
      "txt",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.NBRE_SALLE_BAIN,
      "Nombre de salle de bain",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.NBRE_WC,
      "Nombre de WC",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.NBRE_SALLE_EAU,
      "Nombre de salle d'eau",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.NBRE_ETAGE,
      "Nombre d'étage",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.CHAMBRE_RDC,
      "Nombre de chambre au RDC",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.GRENIER,
      "Grenier",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.ETAT_GENERAL,
      "Etat général",
      "int",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.FENETRE,
      "Type de fenêtres",
      "txt",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.MODE_CHAUFFAGE,
      "Mode de chauffage",
      "txt",
      ""
    );
    fnc_infosComplementaires(
      props.sel.appartement.COUP_COEUR,
      "Coup de Coeur",
      "int",
      ""
    );
    lettreDPE = [
      props.sel.appartement.CONSOMMATIONENERGETIQUE,
      props.sel.appartement.CONSO_ANNUEL_ENERGIE,
    ];
    lettreGES = [
      props.sel.appartement.GAZEFFETDESERRE,
      props.sel.appartement.VALEUR_GES,
    ];
    dateDPEGES = sel.appartement.DATE_DPE;
    estimationAnnuelStandardExpDpe = sel.appartement.ESTIMATION_ANNUEL_STANDARD_EX_DPE;
    chargeMin = sel.appartement.CHARGE_ENERGIE_MIN;
    chargeMax = sel.appartement.CHARGE_ENERGIE_MAX;

  }

  const flecheCarreRight = (
    <FontAwesomeIcon icon={faCaretRight} className="couleurAIB" />
  );

  const testparity = (infosComplementairesArray) => {
    let ret = [];

    for (let step = 0; step < infosComplementairesArray.length; step += 2) {
      if (
        step + 1 < infosComplementairesArray.length &&
        infosComplementairesArray[step + 1][0] !== ""
      ) {
        ret.push(
          <tr key={step}>
            <td>
              {flecheCarreRight} {infosComplementairesArray[step][0]} :{" "}
              {infosComplementairesArray[step][1]}
            </td>
            <td>
              {flecheCarreRight} {infosComplementairesArray[step + 1][0]} :{" "}
              {infosComplementairesArray[step + 1][1]}
            </td>
          </tr>
        );
      }
      if (
        step + 1 < infosComplementairesArray.length &&
        infosComplementairesArray[step + 1][0] === ""
      ) {
        ret.push(
          <tr key={step}>
            <td>
              {flecheCarreRight} {infosComplementairesArray[step][0]} :{" "}
              {infosComplementairesArray[step][1]}
            </td>
            <td>&nbsp;</td>
          </tr>
        );
      }
      if (step === infosComplementairesArray.length - 1) {
        ret.push(
          <tr key={step}>
            <td>
              {flecheCarreRight} {infosComplementairesArray[step][0]} :{" "}
              {infosComplementairesArray[step][1]}
            </td>
            <td>&nbsp;</td>
          </tr>
        );
      }
    }

    if (infosComplementairesArray.length === 0) {
      ret.push(
        <tr key="1">
          <td>{flecheCarreRight} Aucune infos complémentaires</td>
          <td>&nbsp;</td>
        </tr>
      );
    }

    return ret;
  };

  const cebienminterresse = () =>{
    if (selRent === "Yes"){
      return(
        <div className="row titreaib">
    <div className="col-md-12 col-centered">
      <span>Ce bien a déjà été loué!</span>
    </div>
  </div>
      )

    }else{
      return(
        <div className="row titreaib">
    <div className="col-md-12 col-centered">
      <span className="addcursor" onClick={() => clicContact(slug)}>
        Ce bien vous intéresse! Cliquez ici pour nous contacter
      </span>
    </div>
  </div>
      )
    }
    
  }

  return (
    <React.Fragment>
      <div className="col-md-12 divaibblock1  showhide ">
        <div className="row titreaib">
          <Col md={8}>
            <span className="center">{props.sel.intitule.fr}</span>
          </Col>
          <Col md={4}>
            <span className="txtright">Ref: {slug} </span>
          </Col>
        </div>

        <div className="row">
          <Col md={{ span: 10, offset: 1 }} className="showSlider ">
            <Slider
              previousButton={
                <FontAwesomeIcon
                  icon={faChevronCircleLeft}
                  color="#AC0000"
                  size="2x"
                />
              }
              nextButton={
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  color="#AC0000"
                  size="2x"
                />
              }
            >
              {carouselPhotos.map((item, index) => (
                <div
                  className="addpaddingSlider resizeImgBackground"
                  onClick={() => ZoomPhoto(item.img)}
                  key={index}
                  style={{
                    background: `url('${item.img}') no-repeat center center`,
                  }}
                ></div>
              ))}
            </Slider>
          </Col>
        </div>
<div>
          {selVisiteVirtuelle ? (
            <div className="row ">
              <div className="col-md-12 col-centered ">
                <span className="visitevirtuelletxt">
                  Découvrez notre visite virtuelle...
                </span>

              </div>

              <div className="col-md-offset-1 col-md-10  centerVisiteVirtuelle ">
                <iframe 
                  title={selIndex}
                  key={selIndex}
                  className="centerVisiteVirtuelle centerVisiteVirtuelleInside "
                  // width="480"
                  // height="360"
                  width="1024"
                  height="768"  
                  src={selVisiteVirtuelleItem}
                  frameborder="0"
                  allowFullScreen
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12 col-centered">&nbsp;</div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12 col-centered">
            <span className="prix">
              Loyer{" "}
              {formatPrix(
                props.sel.location.LOYER,
                props.sel.location.PROVISION_SUR_CHARGES
              )}
              <br />
              <span className="honoraires">Charges comprises / mois</span>
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <h4 className="textbien">
              <FontAwesomeIcon icon={faPaperclip} />
              &nbsp;&nbsp;&nbsp;DESCRIPTIF
            </h4>
            
            {newDescriptif.map((item, index) => (
              <p key={index}>
                <FontAwesomeIcon icon={faTags} className="couleurAIB" /> {item}
              </p>
            ))}
            <hr />
          </div>
          </div>
          <div className="row">
          <div className="col-md-6 col-centered">
            <Dpe
              lettreDPE={lettreDPE}
              dateDPEGES={dateDPEGES}
              lettreGES={lettreGES}
            />
          </div>
          <div className="col-md-6 col-centered">
            <Ges lettreGES={lettreGES} dateDPEGES={dateDPEGES} />
          </div>
          <div className="col-md-6 col-centered">{before2021(dateDPEGES)}</div>
          <div className="alignLeftcoutannuel">
            {coutAnnuelEnergie(dateDPEGES)}
          </div>
        </div>

        <div className="row ">
          <div className="col-md-offset-1 col-md-10">
            <h4 className="textbien">
              <FontAwesomeIcon icon={faListUl} />
              &nbsp;&nbsp;&nbsp;INFOS COMPLEMENTAIRES
            </h4>
          </div>
          <div className="col-md-12">
            <table className="table table-striped">
              <tbody>{testparity(infosComplementairesArray)}</tbody>
            </table>
          </div>
        </div>
        {/* <div className="row titreaib">
          <div className="col-md-12 col-centered">
            <span className="addcursor" onClick={() => clicContact(slug)}>
              Ce bien vous intéresse! Cliquez ici pour nous contacter
            </span>
          </div>
        </div> */}
        {cebienminterresse()}
      </div>

      <ZoomImg affiche={affiche} cache={toggle} photo={photo} />
    </React.Fragment>
  );
};

export default DetailLocation;
