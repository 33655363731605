const Gotodisplay = (gotop,setGotop, yPos) => {
    const YDefaultValue = 620
    let yPosition

    yPos !== undefined ? yPosition = yPos : yPosition= YDefaultValue

    const goToUp = ()=>{
        window.scrollTo({
          left: 0,
          top: yPosition,
          behavior: "smooth"
        }
          );
      
      }


      if (gotop) {
        goToUp()
        setGotop(false)

      }
        


}
export default Gotodisplay;