import React, { useState} from 'react';
import $ from 'jquery';

const Scrollbuttonsct = () => {

   

      const [is_visible, setis_visible] = useState(false);


    $(window).scroll(function() {
        if ($(this).scrollTop() > 200) {
            $('.scrollToTop').fadeIn();
            setis_visible(true)
        } else {
            $('.scrollToTop').fadeOut();
            setis_visible(false)
        }
    });
    
    //Click event to scroll to top
   
// })



const scrollToTop =()=>{
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }



    return (
        
        
        <React.Fragment>

        {is_visible ? <div className="scrollToTop" onClick={scrollToTop}></div> : ''}
        
        </React.Fragment>
    );
}

export default Scrollbuttonsct;
/*
 $(window).scroll(function() {
        if ($(this).scrollTop() > 200) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });

    //Click event to scroll to top
    $('.scrollToTop').click(function() {
        $('html, body').animate({ scrollTop: 0 }, 800);
        return false;
    });

*/
