import React, { useState, useContext, useEffect } from "react";
import { Container, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import contextannonces from './contextannonces'
import Bien from './bien';
import DetailBien from './detailBien';
//import Typedebiens from './typedebiens';


import noimage from '../images/no-image.png'
import $ from 'jquery';
import Gotodisplay from "./gotodisplay";
import GoogleStat, { TrackGoogleAnalyticsEvent } from "./googleAnalytics";



function isNum(v) {
    return /^\d+$/.test(v);
}



////////////// Debut du composant ////////////

const Biens = (props) => {



      

    const [gotop, setGotop] = useState(true);
    const { listeAnnonces } = useContext(contextannonces);
    Gotodisplay(gotop,setGotop)
    useEffect(() => {

        
        

        $(".mnu").removeClass("menuSelected")
        $("#acheterLink").addClass("menuSelected")
        TrackGoogleAnalyticsEvent(
              "BiensCategory",
              "Acheter",
              window.location.pathname
        )
            
          
    },[])


    let history = useHistory();
    const clicContact = () => {
        history.push('/contact')
    }
    
    
    /////////////////////////////////////////////////////////////////
    const [mestypebiens, setMestypebiens] = useState([])

    const venteOnly1 = listeAnnonces.filter(
        function (bien) {
            return bien.vente_id !== null; 
        }
    ).sort((a, b) => a.vente.vente_type_mandat.localeCompare(b.vente.vente_type_mandat));


    venteOnly1.sort((a,b)=> isNum(b.aff_num) - isNum(a.aff_num))
    
    

    const [venteOnly, setVenteOnly] = useState(venteOnly1)
    const [maliste, setMaliste] = useState(venteOnly)
    const [dataCombobox, setDataCombobox] = useState( ['Prix',  'Croissant', 'Décroissant'])
    
    

    useEffect(() => {

            
        let Arraymestypebiens = ["Tous les biens"];
        
        const getData = async () => {
            const maisons = await (listeAnnonces.filter(
                function (bien) {
                    
                    return (bien.maison_id >= 1 && bien.vente_id >= 1 && bien.statut.substring(1) !== "Vendu" && bien.statut.substring(1) !== "Archive") 
                }
            ).length);
            
            if (maisons >= 1)
                Arraymestypebiens.push("Maisons")

            const demeures = await (listeAnnonces.filter(
                function (bien) {
                    return bien.demeure_id >= 1 && bien.vente_id >= 1
                }
            ).length);
            if (demeures >= 1)
                Arraymestypebiens.push("Demeures")

            const terrains = await (listeAnnonces.filter(
                function (bien) {
                    return bien.terrain_id >= 1 && bien.vente_id >= 1
                }
            ).length);
            if (terrains >= 1)
                Arraymestypebiens.push("Terrains")

            const appartements = await (listeAnnonces.filter(
                function (bien) {
                    return bien.appartement_id >= 1 && bien.vente_id >= 1
                }
            ).length);
            if (appartements >= 1)
                Arraymestypebiens.push("Appartements")

            const localprofessionnels = await (listeAnnonces.filter(
                function (bien) {
                    return bien.localprofessionnel_id >= 1 && bien.vente_id >= 1
                }
            ).length);
            if (localprofessionnels >= 1)
                Arraymestypebiens.push("Locaux Professionnels")

            const immeubles = await (listeAnnonces.filter(
                function (bien) {
                    return bien.immeuble_id >= 1 && bien.vente_id >= 1
                }
            ).length);
            if (immeubles >= 1)
                Arraymestypebiens.push("Immeubles")



            setMestypebiens(Arraymestypebiens)
        }
        getData();

        // Workaround in case page loaded directly
        if (listeAnnonces.length > 0)  {

            const venteOnly11 = listeAnnonces.filter(
                function (bien) {
                    return bien.vente_id !== null; 
                }
            ).sort((a, b) => a.vente.vente_type_mandat.localeCompare(b.vente.vente_type_mandat));
        
            venteOnly11.sort((a,b)=> isNum(b.aff_num) - isNum(a.aff_num))

            setMaliste(venteOnly11)
            setVenteOnly(venteOnly11)
            
            
        }
    }

        , [listeAnnonces])



  



    
    const dataBudgetCombobox = [
        'Tous les prix',
        'Moins de 200 000€',
        'Entre 200 000€ et 300 000€',
        'Entre 300 000€ età 400 000€',
        'Plus de 400 000€'
    ]

    const [trierpar, setTrierpar] =useState("Trier par")
    const [selTri, setSelTri] = useState(dataCombobox[0])
    
    
    const [selBudget, setSelBudget] = useState(dataBudgetCombobox[0])
    const [selTypeDeBiens, setSelTypeDeBiens] = useState('Tous les biens')
    const [selannonce, setSelannonce] = useState('')
    //const [nbBien, setNbBien] = useState(maliste.length)
    const [nbBien, setNbBien] = useState('')






    const handleOnClick = (objBien) => {
        props.history.push('/DetailBien/' + objBien.aff_num)
        DetailBien.defaultProps = { sel: objBien }
    }

    const handleOnChangeFilter = (e) => {
        setTrierpar("Trier par Prix")
        setDataCombobox( ['Croissant', 'Décroissant'])
        
        setSelTri(e.target.value)
        let sel = e.target.value
        let ret = []

        switch (sel) {
            case 'Croissant':
                ret = maliste.sort((a, b) => a.vente.vente_prix - b.vente.vente_prix)
                break;
            case 'Décroissant':
                ret = maliste.sort((a, b) => b.vente.vente_prix - a.vente.vente_prix)
                break;
            case 'Date croissante':
                ret = maliste.sort((a, b) => {
                    let dateA = new Date(a.date_maj)
                    let dateB = new Date(b.date_maj)
                    return dateA - dateB
                });

                break;
            case 'Date décroissante':
                ret = maliste.sort((a, b) => {
                    let dateA = new Date(a.date_maj)
                    let dateB = new Date(b.date_maj)
                    return dateB - dateA
                });

                break;
            default:
                ret = maliste
                break;
        }

        setMaliste(ret)
       // setNbBien(ret.length)
    }




    const handleOnChangeFilterTypeDeBiens = (e) => {
        setSelTypeDeBiens(e.target.value)
        let sel = e.target.value
        let ret = []



        switch (sel) {
            case "Maisons":
                ret = venteOnly.filter(function (item) {
                    
                    return item.maison_id >= 1;
                })
                break;
            case "Demeures":
                ret = venteOnly.filter(function (item) {
                    return item.demeure_id >= 1;
                })
                break;
            case "Terrains":
                ret = venteOnly.filter(function (item) {
                    return item.terrain_id >= 1;
                })
                break;
            case "Appartements":
                ret = venteOnly.filter(function (item) {
                    return item.appartement_id >= 1;
                })
                break;
            case "Locaux Professionnels":
                ret = venteOnly.filter(function (item) {
                    return item.localprofessionnel_id >= 1;
                })
                break;
            case "Immeubles":
                ret = venteOnly.filter(function (item) {
                    return item.immeuble_id >= 1;
                })
                break;
            case "Tous les biens":
                ret = venteOnly
                break;
        }

        setMaliste(ret)
        //setNbBien(ret.length)
        if (sel === "Tous les biens" ){
            setNbBien("")
        }else{
            setNbBien("   (" + ret.length + " biens trouvés)")
        }
        
        setSelBudget(dataBudgetCombobox[0])
        setSelannonce('')

    }

    const handleOnChangeFilterBudget = (e) => {
        setSelBudget(e.target.value)
        let sel = e.target.value
        let ret = []
        //data.sort((a, b) => a.userId - b.userId);
        switch (sel) {
            case dataBudgetCombobox[0]:
                ret = venteOnly
                break;
            case dataBudgetCombobox[1]:
                ret = venteOnly.filter(function (item) {
                    return item.vente.vente_prix <= 200000;
                })
                break;
            case dataBudgetCombobox[2]:
                ret = venteOnly.filter(function (item) {
                    return (item.vente.vente_prix > 200000 && item.vente.vente_prix <= 300000);
                })
                break;
            case dataBudgetCombobox[3]:
                ret = venteOnly.filter(function (item) {
                    return (item.vente.vente_prix > 300000 && item.vente.vente_prix <= 400000);
                })
                break;

            case dataBudgetCombobox[4]:
                ret = venteOnly.filter(function (item) {
                    return item.vente.vente_prix > 400000;
                })
                break;

            default:
                ret = venteOnly
                break;
        }

        let ret1 = []

        
      

        setMaliste(ret)
        //setNbBien(ret.length)
        if (sel === dataBudgetCombobox[0] ){
            setNbBien("")
        }else{
            setNbBien("   (" + ret.length + " biens trouvés)")
        }
        
        setSelTypeDeBiens('Tous les biens')
        setSelannonce('')

    }




    const handleOnChangeFilterAnnonce = (e) => {
        //   const [maliste, setMaliste] = useState([])
        setSelannonce(e.target.value)

        let sel = e.target.value
        let nbBienFound = 0;

        if (sel.length > 0) {

            let found = [];
            venteOnly.forEach(function (elem, index) {

                if (elem.aff_num.includes(sel)) {
                    found.push(elem)
                }
            })
            if (found.length > 0) {
                setMaliste(found)
                nbBienFound = found.length;
            } else {
                setMaliste([])
                //nbBienFound = venteOnly.length;

            }
        } else {
            setMaliste(venteOnly)
            nbBienFound = venteOnly.length;
        }
        //setNbBien(nbBienFound)
        if (nbBienFound !== venteOnly.length){
            setNbBien("   (" + nbBienFound + " biens trouvés)")
        }else{
            setNbBien("")
        }
        
        setSelTypeDeBiens('Tous les biens')
        setSelBudget(dataBudgetCombobox[0])

    }


    const txt_charge_honoraires = (charge_honoraires) => {
        let ret;
        charge_honoraires = charge_honoraires.toLowerCase()
        if (charge_honoraires == "acquéreur"){
            ret = "Honoraires à la charge de l'" + charge_honoraires 
        }
        if (charge_honoraires == "vendeur"){
            ret = "Honoraires à la charge du " + charge_honoraires 
        }
        if (charge_honoraires == "acquéreur et vendeur"){
            ret = "Honoraires à la charge de l'acquéreur et du vendeur"
        }
        return ret
    }


    const testIfImgExist = (monBien) => {
        //console.log(monBien.imagesbien)
        //let ret = monBien.imagesbien[0].img;
        let ret;
        if (monBien.imagesbien.length === 0) {
            ret = noimage;
        } else {
            ret = monBien.imagesbien[0].img;
        }

        return ret;
    }



    return (



        <Container className="containerAib">

            <Row>
                <div className="col-md-12 ">
                    <ol className="breadcrumb">
                        <li className="active">&nbsp;&nbsp;Acheter un bien</li>
                    </ol>
                </div>
            </Row>
            <div className="row aib-agence ">
                <div className="col-md-10 offset-md-1  divaibblock2" >
                    <div className="row" >
                        <div className="col-md-12">
                            <p className="txttitre ">Vos critères de recherche <span>{nbBien}</span> </p> 
                        </div>
                    </div>

                    <div className="row reducemargintop ">
                    <div className="col-md-3 col-centered reducemarginright ">
                            <label htmlFor="TrierPar">{trierpar}&nbsp;&nbsp; </label>
                            <select id="TrierPar" value={selTri} onChange={handleOnChangeFilter} >
                                {dataCombobox.map((item, index) => (
                                    <option key={index}>{item}</option>
                                ))}

                            </select>

                        </div>
                        
                        <div className="col-md-3 col-centered reducemarginright ">
                            <label htmlFor="TypeDeBiens">Type de Biens&nbsp;&nbsp; </label>
                            <select id="TypeDeBiens" value={selTypeDeBiens} onChange={handleOnChangeFilterTypeDeBiens} >
                                {mestypebiens.map((item, index) => (
                                    <option key={index}>{item} </option>
                                ))}
                            </select>
                        </div>
                        
                        <div className="col-md-3 col-centered reducemarginright">
                            <label htmlFor="Budget">Votre Budget&nbsp;&nbsp; </label>
                            <select id="Budget" value={selBudget} onChange={handleOnChangeFilterBudget} >
                                {dataBudgetCombobox.map((item, index) => (
                                    <option key={index}>{item}</option>
                                ))}
                            </select>
                        </div>
                        
                        
                        <div className="col-md-3 col-centered addmarginright ">
                            <label htmlFor="chercherParRef">Chercher par référence&nbsp;&nbsp; </label>
                            <input size="8" id="chercherParRef" type='text' value={selannonce} onChange={handleOnChangeFilterAnnonce} />

                        </div>
                    </div>


              


                    
                    
                    


                </div>
            </div>

            <div className="row">

                
                    
                
                
                             
                {maliste.map((monBien, index) => (
                    <div key={index} className="col-md-4 col-centered" onClick={() => handleOnClick(monBien)}>

                        <Bien
                            key={monBien.id}
                            aff_num={monBien.aff_num}
                            vente_prix={monBien.vente.vente_prix}
                            titre={monBien.intitule.fr}
                            date_maj={monBien.date_maj}
                            type_mandat={monBien.vente.vente_type_mandat}
                            charge_honoraires={txt_charge_honoraires(monBien.vente.vente_charge_honoraires)}


                            img={<img src={testIfImgExist(monBien)} className="card-img-top "  alt={index} />}
                        />
                    </div>
                ))}
            </div>
            <div className="row titreaib">
                    <div className="col-md-12 col-centered">
                        <span className="addcursor" onClick={clicContact}>VOUS N'AVEZ PAS TROUVE VOTRE IDEAL CLIQUEZ ICI POUR NOUS CONTACTER</span>
                    </div>
                </div>
            


        </Container>



    );
}





export default Biens;