import React from 'react'

const goToUp = ()=>{
    window.scrollTo(0, 0);
}


const ZoomImg  = ({affiche, cache, photo}) => affiche ? (

    <React.Fragment>
      {goToUp()}
        
        <div className="overlay1">
        
        <div className="wrapper1">
        
            <img src={photo} className="addpadding1" alt="photo1"/>
            <div className="pos">
                <button className="btn btn-primary buttonInfo " onClick={cache} ><span>Fermer</span></button>
            </div>
        </div>
        </div>
       

    </React.Fragment>

): null;


export default ZoomImg;