import React, {useEffect} from "react";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'



const convertDate = (dateToConvert) =>{
    const myDate = new Date(dateToConvert);
    return myDate.toLocaleDateString('fr-FR');
}


const honorairesrentyesno = (rent)=>{
    let ret;
    if (rent == "Yes"){
        ret = ""
    }else{
        ret = (<span className="honoraires displaydessous">Charges comprises / mois</span>)
    }
    return ret
}


const bienrentyesno = (rent) =>{
    let ret;
    if (rent == "Yes"){
        ret = ""
    }else{
        ret = (<span><button className="buttonInfo btn btn-primary"><FontAwesomeIcon icon={faAngleDoubleLeft} /> Plus d'infos <FontAwesomeIcon icon={faAngleDoubleRight} /></button></span>)
    }
    return ret
}


const formatPrix = (prix, charge,rent) =>{
    let ret = ""
    
    if (rent == "Yes"){
        ret = (<span>&nbsp;<br/>&nbsp;</span>)
    }else{
        let prixTTC = prix + charge;
        // let prix_str = prix.toString();
        let prix_str = prixTTC.toString();
    
        ret =  ("Loyer " +  prix_str.substr(0, prix_str.length - 3) + "  " + prix_str.substr(prix_str.length - 3) + " €")
        
    }
    return ret
}   




    const Location = (props)=>{
        

        useEffect(() => {
        
            $(".mnu").removeClass("menuSelected") 
            if (props.rent === "Yes"){
                $("#biensVendusLink").addClass("menuSelected") 
            }else{
                $("#locLink").addClass("menuSelected")         
            }
            
          })   


        const rent = ()=>{
        
            if  (props.rent === "Yes" ){ 
                return <div className="exclusivitebandeau">Loué</div>
            }else{
                return '';
            }
    
        }  

        return(
            
            
            <React.Fragment>

                <div className="row">
                <div className="col-md-12  p-2">
                    <div className="card divaibblock1  showhide" >
                      {rent()}
                            {props.img}
                         
                            <div className="card-body titreaibmsg">    
                                <p className="card-text ">
                                <span className="center txt-titre">{props.titre}</span>
                                </p><p className="txtrightreducesize ">
                                    <span >Ref : {props.aff_num} </span>
                                </p>
                            </div>
                            <div className="card-body ">
                                <p className="card-text ">
                                    <span className="prix">
                                    
                                         {formatPrix(props.loyer_prix, props.loyer_charge, props.rent)}

                                    
                                         {honorairesrentyesno(props.rent)}
                                         
                                    
                                    {bienrentyesno(props.rent)}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

  
              

          </React.Fragment>
            
        );
    }

//}



export default Location  ;