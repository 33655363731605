import React, { Fragment, useState } from "react";

import DpeAbefore2021 from "../images/dpe_ges/DPE_Before_2021/dpe_A1.png";
import DpeBbefore2021 from "../images/dpe_ges/DPE_Before_2021/dpe_B1.png";
import DpeCbefore2021 from "../images/dpe_ges/DPE_Before_2021/dpe_C1.png";
import DpeDbefore2021 from "../images/dpe_ges/DPE_Before_2021/dpe_D1.png";
import DpeEbefore2021 from "../images/dpe_ges/DPE_Before_2021/dpe_E1.png";
import DpeFbefore2021 from "../images/dpe_ges/DPE_Before_2021/dpe_F1.png";
import DpeGbefore2021 from "../images/dpe_ges/DPE_Before_2021/dpe_G1.png";
import DpeVal from "../images/dpe_ges/DPE_Before_2021/dpe_val.png";

import DpeAAfter from "../images/dpe_ges/DPE_After_2021/dpe-A.png";
import DpeBAfter from "../images/dpe_ges/DPE_After_2021/dpe-B.png";
import DpeCAfter from "../images/dpe_ges/DPE_After_2021/dpe-C.png";
import DpeDAfter from "../images/dpe_ges/DPE_After_2021/dpe-D.png";
import DpeEAfter from "../images/dpe_ges/DPE_After_2021/dpe-E.png";
import DpeFAfter from "../images/dpe_ges/DPE_After_2021/dpe-F.png";
import DpeGAfter from "../images/dpe_ges/DPE_After_2021/dpe-G.png";

import DpeASelectAfter from "../images/dpe_ges/DPE_After_2021/dpe-A_Select.png";
import DpeBSelectAfter from "../images/dpe_ges/DPE_After_2021/dpe-B_Select.png";
import DpeCSelectAfter from "../images/dpe_ges/DPE_After_2021/dpe-C_Select.png";
import DpeDSelectAfter from "../images/dpe_ges/DPE_After_2021/dpe-D_Select.png";
import DpeESelectAfter from "../images/dpe_ges/DPE_After_2021/dpe-E_Select.png";
import DpeFSelectAfter from "../images/dpe_ges/DPE_After_2021/dpe-F_Select.png";
import DpeGSelectAfter from "../images/dpe_ges/DPE_After_2021/dpe-G_Select.png";

const Dpe = (props) => {
  
  const [lettreDPE] = useState(props.lettreDPE[0]);
  // const [lettreDPE] = useState("G")  // for debug

  const [dateDPEGES] = useState(props.dateDPEGES);
  const [numDPE] = useState(props.lettreDPE[1]);
  const [numGES] = useState(props.lettreGES[1]);
  const [viergeOrNot] = useState(props.lettreGES[0]);
  
  
  

  // const [numDPE] = useState(130)
  // const [numGES] = useState(130)

  const dateDPEGESPivot = new Date(2021, 6, 1); // 1er juillet 2021
  const dateDPEGESSplit = dateDPEGES.split("/");
  const dateDPEGESFormatDate = new Date(
    dateDPEGESSplit[2],
    dateDPEGESSplit[1] - 1,
    dateDPEGESSplit[0]
  ); //YY/MM/DD
  // console.log("dateDPE " + dateDPEGES)
  // console.log("numGES " + numGES)
  // console.log("numDPE " + numDPE)



  const isDPEVierge = () =>{
   if (viergeOrNot.toLowerCase() == "vierge"){
    return <div className='dpeVierge'>DPE Vierge</div>;
   }else{
    return "";
   }
  }  

  const passoire = () => {
    if (dateDPEGESFormatDate >= dateDPEGESPivot) {
      return <th className="txtVertical" rowSpan="2">passoire<br />énergétique</th> 
    }else {
      return <th rowSpan="2">&nbsp;</th> 
    }

  }
    const imgSelect = (indexTDPE) => {
    let ret = "";

    // console.log(dateDPEGES)
    // console.log(dateDPEGESFormatDate)
    // console.log(dateDPEGESPivot)
    if (dateDPEGESFormatDate >= dateDPEGESPivot) {
      switch (indexTDPE) {
        case "A":
          if (indexTDPE === lettreDPE) {
            ret = (
              <Fragment>
                <td>
                  <div className="contenant">
                    <img src={DpeASelectAfter} alt={indexTDPE} />
                    <div className="superposeTxt">
                      
                      <span className="fontplus">{numDPE}</span>
                      <br />
                      <span className="fontmoins">
                        kWh<sub>EP</sub>/m<sup>2</sup>.an
                      </span>
                    </div>
                    <div className="superposeTxtRight">
                      <span className="fontplus">{numGES}</span>
                      <br />
                      <span className="fontmoins">
                        kg CO<sub>2</sub>/m<sup>2</sup>/an
                      </span>
                    </div>
                  </div>
                </td>
                <td></td>
              </Fragment>
            );
          } else {
            ret = (
              <Fragment>
                <td className="tdFillSpace">&nbsp;</td>
                <td className="contenantDPE">
                  <img src={DpeAAfter} alt={indexTDPE} />
                </td>
              </Fragment>
            );
          }
          break;
        case "B":
          if (indexTDPE === lettreDPE) {
            ret = (
              <Fragment>
                <td>
                  <div className="contenant">
                    <img src={DpeBSelectAfter} alt={indexTDPE} />
                    <div className="superposeTxt">
                      {" "}
                      <span className="fontplus">{numDPE}</span>
                      <br />
                      <span className="fontmoins">
                        kWh<sub>EP</sub>/m<sup>2</sup>.an
                      </span>
                    </div>
                    <div className="superposeTxtRight">
                      <span className="fontplus">{numGES}</span>
                      <br />
                      <span className="fontmoins">
                        kg CO<sub>2</sub>/m<sup>2</sup>/an
                      </span>
                    </div>
                  </div>
                </td>
                <td></td>
              </Fragment>
            );
          } else {
            ret = (
              <Fragment>
                <td className="tdFillSpace">&nbsp;</td>
                <td className="contenantDPE">
                  <img src={DpeBAfter} alt={indexTDPE} />
                </td>
              </Fragment>
            );
          }
          break;
        case "C":
          if (indexTDPE === lettreDPE) {
            ret = (
              <Fragment>
                <td>
                  <div className="contenant">
                    <img src={DpeCSelectAfter} alt={indexTDPE} />
                    <div className="superposeTxt">
                      {" "}
                      <span className="fontplus">{numDPE}</span>
                      <br />
                      <span className="fontmoins">
                        kWh<sub>EP</sub>/m<sup>2</sup>.an
                      </span>
                    </div>
                    <div className="superposeTxtRight">
                      <span className="fontplus">{numGES}</span>
                      <br />
                      <span className="fontmoins">
                        kg CO<sub>2</sub>/m<sup>2</sup>/an
                      </span>
                    </div>
                  </div>
                </td>
                <td></td>
              </Fragment>
            );
          } else {
            ret = (
              <Fragment>
                <td className="tdFillSpace">&nbsp;</td>
                <td className="contenantDPE">
                  <img src={DpeCAfter} alt={indexTDPE} />
                </td>
              </Fragment>
            );
          }
          break;
        case "D":
          if (indexTDPE === lettreDPE) {
            ret = (
              <Fragment>
                <td>
                  <div className="contenant">
                    <img src={DpeDSelectAfter} alt={indexTDPE} />
                    <div className="superposeTxt">
                      {" "}
                      <span className="fontplus">{numDPE}</span>
                      <br />
                      <span className="fontmoins">
                        kWh<sub>EP</sub>/m<sup>2</sup>.an
                      </span>
                    </div>
                    <div className="superposeTxtRight">
                      <span className="fontplus">{numGES}</span>
                      <br />
                      <span className="fontmoins">
                        kg CO<sub>2</sub>/m<sup>2</sup>/an
                      </span>
                    </div>
                  </div>
                </td>
                <td></td>
              </Fragment>
            );
          } else {
            ret = (
              <Fragment>
                <td className="tdFillSpace">&nbsp;</td>
                <td className="contenantDPE">
                  <img src={DpeDAfter} alt={indexTDPE} />
                </td>
              </Fragment>
            );
          }
          break;
        case "E":
          if (indexTDPE === lettreDPE) {
            // style={{backgroundImage: `url('${DpeVal}')` ,   backgroundRepeat  : 'no-repeat' }}
            ret = (
              <Fragment>
                <td>
                  <div className="contenant">
                    <img src={DpeESelectAfter} alt={indexTDPE} />
                    <div className="superposeTxt">
                      {" "}
                      <span className="fontplus">{numDPE}</span>
                      <br />
                      <span className="fontmoins">
                        kWh<sub>EP</sub>/m<sup>2</sup>.an
                      </span>
                    </div>
                    <div className="superposeTxtRight">
                      <span className="fontplus">{numGES}</span>
                      <br />
                      <span className="fontmoins">
                        kg CO<sub>2</sub>/m<sup>2</sup>/an
                      </span>
                    </div>
                  </div>
                </td>
                <td></td>
              </Fragment>
            );
          } else {
            ret = (
              <Fragment>
                <td className="tdFillSpace">&nbsp;</td>
                <td className="contenantDPE">
                  <img src={DpeEAfter} alt={indexTDPE} />
                </td>
              </Fragment>
            );
          }
          break;
        case "F":
          if (indexTDPE === lettreDPE) {
            ret = (
              <Fragment>
                <td>
                  <div className="contenant">
                    <img src={DpeFSelectAfter} alt={indexTDPE} />
                    <div className="superposeTxt">
                      {" "}
                      <span className="fontplus">{numDPE}</span>
                      <br />
                      <span className="fontmoins">
                        kWh<sub>EP</sub>/m<sup>2</sup>.an
                      </span>
                    </div>
                    <div className="superposeTxtRight">
                      <span className="fontplus">{numGES}</span>
                      <br />
                      <span className="fontmoins">
                        kg CO<sub>2</sub>/m<sup>2</sup>/an
                      </span>
                    </div>
                  </div>
                </td>
                <td></td>
              </Fragment>
            );
          } else {
            ret = (
              <Fragment>
                <td className="tdFillSpace">&nbsp;</td>
                <td className="contenantDPE">
                  <img src={DpeFAfter} alt={indexTDPE} />
                </td>
              </Fragment>
            );
          }
          break;
        case "G":
          if (indexTDPE === lettreDPE) {
            ret = (
              <Fragment>
                <td>
                  <div className="contenant">
                    <img src={DpeGSelectAfter} alt={indexTDPE} />
                    <div className="superposeTxt">
                      {" "}
                      <span className="fontplus">{numDPE}</span>
                      <br />
                      <span className="fontmoins">
                        kWh<sub>EP</sub>/m<sup>2</sup>.an
                      </span>
                    </div>
                    <div className="superposeTxtRight">
                      <span className="fontplus">{numGES}</span>
                      <br />
                      <span className="fontmoins">
                        kg CO<sub>2</sub>/m<sup>2</sup>/an
                      </span>
                    </div>
                  </div>
                </td>
                <td></td>
              </Fragment>
            );
          } else {
            ret = (
              <Fragment>
                <td className="tdFillSpace">&nbsp;</td>
                <td className="contenantDPE">
                  <img src={DpeGAfter} alt={indexTDPE} />
                </td>
              </Fragment>
            );
          }
          break;
      }
    } else {
      switch (indexTDPE) {
        case "A":
          ret = <img src={DpeAbefore2021} alt={indexTDPE} />;
          break;
        case "B":
          ret = <img src={DpeBbefore2021} alt={indexTDPE} />;
          break;
        case "C":
          ret = <img src={DpeCbefore2021} alt={indexTDPE} />;
          break;
        case "D":
          ret = <img src={DpeDbefore2021} alt={indexTDPE} />;
          break;
        case "E":
          ret = <img src={DpeEbefore2021} alt={indexTDPE} />;
          break;
        case "F":
          ret = <img src={DpeFbefore2021} alt={indexTDPE} />;
          break;
        case "G":
          ret = <img src={DpeGbefore2021} alt={indexTDPE} />;
          break;
      }
    }
    return ret;
  };

  const afficheDPEImg = (indexTDPE) => {
    let ret = <span></span>;

    if (dateDPEGESFormatDate >= dateDPEGESPivot) {
      if (indexTDPE === lettreDPE) {
        // ret = <td className="addDPE" style={{backgroundImage: `url('${DpeVal}')` ,   backgroundRepeat  : 'no-repeat' }} >{numDPE}&nbsp;&nbsp;&nbsp;</td>
        ret = <td>&nbsp;&nbsp;&nbsp;</td>;
      } else {
        ret = <td>&nbsp;&nbsp;&nbsp;</td>;
      }
    } else {
      if (indexTDPE === lettreDPE) {
        ret = (
          <td
            className="addDPE coverDpeGesImg"
            style={{
              backgroundImage: `url('${DpeVal}')`,
              // backgroundRepeat: "no-repeat",
            }}
          >
            {numDPE}&nbsp;&nbsp;&nbsp;
          </td>
        );
      } else {
        ret = <td>&nbsp;&nbsp;&nbsp;</td>;
      }
    }

    // if (numDPE === 0 || numDPE === undefined || numDPE === "") {
    //     ret = <td className="dpeUnknown">DPE non communiqué</td>
    // }

    if (numDPE === 0 || numDPE === undefined || numDPE === "") {
      // ret = <td className="dpeUnknown">DPE non communiqué</td>   ==> A corriger!
      ret = <td>&nbsp;&nbsp;&nbsp;</td>;
    }

    return ret;
  };

   

  return (
    <React.Fragment>
      {isDPEVierge()}
      <table className="dpetable">
      
        <tbody >
          <tr>
            <th>&nbsp;</th>
            <td> &nbsp;</td>
            <td className="tdFillSpace" >&nbsp;</td>
            <td align="center">
              <span className="reducefont">Logement économe</span>
            </td>
          </tr>

          <tr>
            <th>&nbsp;</th>
            <td>
              {/* <img src={DpeA} alt="A" /> */}
              {imgSelect("A")}
            </td>
            {afficheDPEImg("A")}
          </tr>

          <tr>
            <th>&nbsp;</th>
            <td>
              {/* <img src={DpeB} alt="B" /> */}
              {imgSelect("B")}
            </td>
            {afficheDPEImg("B")}
          </tr>
          <tr>
            <th>&nbsp;</th>
            <td>
              {/* <img src={DpeC} alt="C" /> */}
              {imgSelect("C")}
            </td>
            {afficheDPEImg("C")}
          </tr>
          <tr>
            <th>&nbsp;</th>
            <td>
              {/* <img src={DpeD} alt="D" /> */}
              {imgSelect("D")}
            </td>
            {afficheDPEImg("D")}
          </tr>
          <tr>
            <th>&nbsp;</th>
            <td>
              {/* <img src={DpeE} alt="E" /> */}
              {imgSelect("E")}
            </td>
            {afficheDPEImg("E")}
          </tr>
          <tr>
          {passoire()}
            <td>
              {/* <img src={DpeF} alt="F" /> */}
              {imgSelect("F")}
            </td>
            {afficheDPEImg("F")}
          </tr>
          <tr>
            <td>
              {/* <img src={DpeG} alt="G" /> */}
              {imgSelect("G")}
            </td>
            {afficheDPEImg("G")}
          </tr>
          <tr>
            <th>&nbsp;</th>
            <td> &nbsp;</td>
            <td className="tdFillSpace" >&nbsp;</td>
            <td align="center">
              <span className="reducefont">Logement énergivore</span>
            </td>
            
          </tr>

        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className="resetTd" colSpan="2" align="center">
              <span className="reducefont">
                Le DPE est exprimé en kWh<sub>EP</sub>/m<sup>2</sup>.an
              </span>
            </td>
          </tr>
          
        </tbody>
      </table>
    </React.Fragment>
  );
};
export default Dpe;
