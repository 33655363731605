import React, {useEffect, useState} from "react";
import { Row, Col, Container } from 'react-bootstrap';
import Christine from "../images/equipeAib/christine1.jpg"
import Kevin from "../images/equipeAib/kevin.jpg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import MapAib from './mapaib';
import $ from 'jquery'
import Gotodisplay from "./gotodisplay";



const Agence = () => {
  const [gotop, setGotop] = useState(true);
  Gotodisplay(gotop,setGotop)
  useEffect(() => {
    $(".mnu").removeClass("menuSelected") 
    $("#agenceLink").addClass("menuSelected") 
    
  })

  return (

    <Container className="containerAib">
                        <Row>
        <div className="col-md-12 ">
          <ol className="breadcrumb">
            <li className="active">&nbsp;&nbsp;Notre Agence</li>
          </ol>
        </div>
      </Row>
      <div className="row aib-agence  p-3">

        <div className="col-md-8 offset-md-2  divaibblock1" >
          <div className="row">
            <div className="col-md-12 ">
              <p className="txttitre centerTxt">
                Christine Billon
          </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 centerTxt">

              <img src={Christine} className="rounded-circle" height="120px" alt="Christine"/>
            </div>
            <div className="col-md-6">

              <p>
                <span><FontAwesomeIcon icon={faMobileAlt} color="#AC0000" /></span>
                <span className="p-3">06.13.53.20.72</span>
              </p>
              <p>
                <span><FontAwesomeIcon icon={faPhoneAlt} color="#AC0000" /></span>
                <span className="p-3">09.86.48.27.10</span>

              </p>
              <p>
                <span><FontAwesomeIcon icon={faEnvelope} color="#AC0000" /></span>
                <span className="p-3"><a className="mail" href='mailto:contact@aibimmobilier.fr'>contact@aibimmobilier.fr</a></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row  aib-agence p-3">

        <div className="col-md-8 offset-md-2 divaibblock1" >
          <div className="row">
            <div className="col-md-12 ">
              <p className="txttitre centerTxt">
                Kevin Martin
          </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 centerTxt">

              <img src={Kevin} className="rounded-circle " height="120px" alt="Kevin" />
            </div>
            <div className="col-md-6">
              <p>
                <span><FontAwesomeIcon icon={faMobileAlt} color="#AC0000" /></span>
                <span className="p-3">07.67.39.41.39</span>
              </p>
              <p>
                <span><FontAwesomeIcon icon={faPhoneAlt} color="#AC0000" /></span>
                <span className="p-3">09.86.48.27.10</span>

              </p>
              <p>
                <span><FontAwesomeIcon icon={faEnvelope} color="#AC0000" /></span>
                <span className="p-3"><a className="mail" href='mailto:contact@aibimmobilier.fr'>contact@aibimmobilier.fr</a></span>
              </p>






            </div>
          </div>
        </div>
      </div>

      <div className="row   aib-agence  ">
        <div className="col-md-10 offset-md-1  divaibblock1 centerTxt">

          <div className="row">

            <div className="col-md-10 offset-md-1  centerTxt">

              <p className="aib-center"><b><u>Horaires d'ouverture:</u></b></p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-2 pLeft ">
              <p><span><FontAwesomeIcon icon={faCaretRight} color="#AC0000" /></span><span className="p-3">  le lundi sur rendez-vous </span></p>
              <p><span><FontAwesomeIcon icon={faCaretRight} color="#AC0000" /></span><span className="p-3">  du mardi au samedi de 10h00 à 19h00 </span></p>


            </div>
          </div>
          <div className="row">
            <div className="col-md-11 offset-md-1 pLeft ">
            <p>
        Nous vous conseillons de nous téléphoner préalablement afin de vous assurer que nous ne sommes pas en rendez-vous extérieur.
      </p>   
      <p>Si le bureau est fermé, nous restons joignables par téléphone au <b>09.86.48.27.10</b> ou <a href='mailto:contact@aibimmobilier.fr'>email</a>.</p>
            </div>
          </div>
          <Row>
              <Col md={{ span: 10, offset: 1 }} className="addpadding1">
                      <MapAib />
                    </Col>
                  </Row>

        </div>

      </div>
    </Container>


  )
}

export default Agence;