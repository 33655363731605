import React, { Component } from "react";
import { Row, Col} from 'react-bootstrap';
//import { Route } from "react-router-dom";
//import styled from 'styled-components'
//import ReactDOM from "react-dom";



class Headerright extends Component {



  render(){
  return (
    <React.Fragment>
                        <Row>
                            <div className="col-md-12 fontlogodroiteaib">
                                VENTES | LOCATIONS | INVESTISSEMENTS | CONSEILS
                            </div>
                        </Row>
                        <Row>
                            <Col md={12} className="fontlogodroiteaibNormal">
                                <span className="fontlogodroiteaibNormal bold">22 Place de l'Eglise - 78660 ABLIS</span><br/>
                                <span><a className="fontlogodroiteaibNormal" style={{textDecoration: 'none'}} href='mailto:contact@aibimmobilier.fr'>contact@aibimmobilier.fr</a></span><br/>
                                <span><a className="fontlogodroiteaibNormal" style={{textDecoration: 'none'}} href='http://www.aibimmobilier.fr' rel="noopener noreferrer" target="_blank">www.aibimmobilier.fr</a></span>
                            </Col>
                            </Row>
                            <Row>
                            <Col md={12} className="fonttelaib">
                            
                                <span>Tél: 09 86 48 27 10</span>
                            </Col>
                            </Row>
                        </React.Fragment>
  )
  }
}

export default Headerright;