import React from 'react';
import {  Row, Col , Container} from 'react-bootstrap'








const Nobien = (props) => {

    
    

  return (
<Container className="containerAib">


          <Row>
          <Col md={{ span: 8, offset: 2 }} className="txttitre1">

          
            <p className="addpadding3">Désolé, ce bien n'est plus disponible à la {props.typedebien} !</p>
            
          </Col>
          </Row>



</Container>
          



  );
}

export default Nobien;

