import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

const flecheCarreRight = <FontAwesomeIcon icon={faCaretRight} className="aib-color" /> 

const MessageInfosRecueillies = () =>{
 
  
  return(
    <div className="reducefont">
                      {flecheCarreRight} Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Aib Immobilier pour gérer votre demande de contact. Elles sont conservées pour la durée nécessaire à la gestion de la relation client dans le respect des prescriptions légales applicables et sont destinées à nos conseillers Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant Aib Immobilier à <a href='mailto:contact@aibimmobilier.fr'>contact@aibimmobilier.fr</a>. Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : <a target="_blank" href="https://www.bloctel.gouv.fr/">https://www.bloctel.gouv.fr/</a> 
                      </div>
  )
}

export default MessageInfosRecueillies;