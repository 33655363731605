import React, { useState, useContext, useEffect } from "react";
import { Container, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import contextannonces from './contextannonces'
import Bien from './bien';
import Location from './location';
import DetailBien from './detailBien';
import DetailLocation from './detailLocation';


import noimage from '../images/no-image.png'
import $ from 'jquery';
import Gotodisplay from "./gotodisplay";




function isNum(v) {
    return /^\d+$/.test(v);
}


const Biensvendus = (props) => {
    const [gotop, setGotop] = useState(true);
    Gotodisplay(gotop,setGotop)
    let history = useHistory();
    const { listeAnnoncesVendus } = useContext(contextannonces);
    const venteOnly1 = listeAnnoncesVendus.filter(
        function (bien) {
            return (bien.vente_id !== null && bien.vente.vente_signature_date_acte  !== null); 
        }
    ).sort((b, a) => a.vente.vente_signature_date_acte.localeCompare(b.vente.vente_signature_date_acte));

    const [venteOnly, setVenteOnly] = useState(venteOnly1)
    const [maliste, setMaliste] = useState(venteOnly)    

    // Location    
    const locationOnly1 = listeAnnoncesVendus.filter(
        function (bien) {
            return (bien.location_id !== null); 
        }
    ).sort((a, b) => a.location.TYPE_MANDAT.localeCompare(b.location.TYPE_MANDAT));
     
    locationOnly1.sort((a,b)=> isNum(b.aff_num) - isNum(a.aff_num))

    const [locationOnly, setLocationOnly] = useState(locationOnly1)

   
    

    useEffect(() => {

  
        // Workaround in case page loaded directly
        if (listeAnnoncesVendus.length > 0)  {

            const venteOnly11 = listeAnnoncesVendus.filter(
                function (bien) {
                    return bien.vente_id !== null; 
                }
            ).sort((a, b) => a.vente.vente_signature_date_acte.localeCompare(b.vente.vente_signature_date_acte));
        
            venteOnly11.sort((a,b)=> isNum(b.aff_num) - isNum(a.aff_num))

            setMaliste(venteOnly11)
            setVenteOnly(venteOnly11)

            const locationOnly11 = listeAnnoncesVendus.filter(
                function (bien) {
                    return (bien.location_id !== null); 
                }
            ).sort((a, b) => a.location.TYPE_MANDAT.localeCompare(b.location.TYPE_MANDAT));
        
              
            locationOnly11.sort((a,b)=> isNum(b.aff_num) - isNum(a.aff_num))

            setLocationOnly(locationOnly11)
            
            
        }
  
      },[listeAnnoncesVendus])
    


    const handleOnClick = (objBien) => {
        props.history.push('/DetailBien/' + objBien.aff_num)
        DetailBien.defaultProps = { sel: objBien, vendu: "Yes" }
    }

    const handleOnClickLocation = (objBien) => {
        props.history.push('/DetailLocation/' + objBien.aff_num)
        DetailLocation.defaultProps = { sel: objBien, rent: "Yes" }
    }
    

    

    const txt_charge_honoraires = (charge_honoraires) => {
        let ret;
        charge_honoraires = charge_honoraires.toLowerCase()
        if (charge_honoraires === "acquéreur"){
            ret = "Honoraires à charge de l'" + charge_honoraires 
        }
        if (charge_honoraires === "vendeur"){
            ret = "Honoraires à charge du " + charge_honoraires 
        }
        if (charge_honoraires === "acquéreur et vendeur"){
            ret = "Honoraires à charge de l'acquéreur et du vendeur"
        }
        return ret
    }


    const testIfImgExist = (monBien) => {
        
        let ret;
        if (monBien.imagesbien.length === 0) {
            ret = noimage;
        } else {
            ret = monBien.imagesbien[0].img;
        }

        return ret;
    }



    return (



        <Container className="containerAib">

            <Row>
                <div className="col-md-12 ">
                    <ol className="breadcrumb">
                        <li className="active">&nbsp;&nbsp;Nos biens vendus ou loués</li>
                    </ol>
                </div>
            </Row>
            

            <div className="row">

                {maliste.map((monBien, index) => (
                    // <div key={index} className="col-md-4 col-centered" onClick={() => handleOnClick(monBien)}>
                    <div key={index} className="col-md-4 col-centered" >

                        <Bien
                            key={monBien.id}
                            aff_num={monBien.aff_num}
                            // vente_prix={monBien.vente.vente_prix}
                            vente_prix={monBien.vente.vente_prix_reel_vente}
                            titre={monBien.intitule.fr}
                            date_maj={monBien.date_maj}
                            type_mandat={monBien.vente.vente_type_mandat}
                            charge_honoraires={txt_charge_honoraires(monBien.vente.vente_charge_honoraires)}
                            vendu="Yes"
                            vente_signature_date_acte={monBien.vente.vente_signature_date_acte}
                            img={<img src={testIfImgExist(monBien)} className="card-img-top "  alt={index} />}
                        />
                    </div>
                ))}
                {locationOnly.map((monBien, index) => (
                    // <div key={index} className="col-md-4 col-centered" onClick={() => handleOnClickLocation(monBien)}>
                    <div key={index} className="col-md-4 col-centered" >

                <Location
                            key={monBien.id}
                            aff_num={monBien.aff_num}
                            loyer_prix={monBien.location.LOYER}
                            loyer_charge={monBien.location.PROVISION_SUR_CHARGES}
                            titre={monBien.intitule.fr}
                            date_maj={monBien.date_maj}
                            rent="Yes"
                            img={<img src={testIfImgExist(monBien)} className="card-img-top " width="370" alt={index} />}
                        />
                    </div>
                ))}
            </div>



        </Container>



    );
}





export default Biensvendus;