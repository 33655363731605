import React , { useState , useEffect }  from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Container } from 'react-bootstrap';
import {Cookies} from "react-cookie";







const Mentionslegales = () =>{
  const flecheCarreRight = <FontAwesomeIcon icon={faCaretRight} className="aib-color" />
  const iconDownload = <FontAwesomeIcon icon={faDownload} className="aib-color" />
  const fileRGPD = "/2018-informations_RGPD_AIB.pdf"
  const [goToHome, setGoToHome] = useState(false)
  
  useEffect(() => {
    
    
    if (goToHome === true){
    
        const cookies = new Cookies()
        let gaCookies = cookies.getAll()
        Object.entries(gaCookies).map(([key, value]) => (
            cookies.remove(key, {domain :'.aibimmobilier.fr'}),
            cookies.remove(key, {domain :'.aibimmobilier.com'}),
            cookies.remove(key, {domain :'www.aibimmobilier.fr'}),
            cookies.remove(key, {domain :'www.aibimmobilier.com'})
        ))
        cookies.remove("CookieConsent");    
        window.location.replace('/')
        
     
    }
    })
  


  const paramCookies = () =>{
    
    // const cookies = new Cookies()

    // let gaCookies = cookies.getAll()

    // Object.entries(gaCookies).map(([key, value]) => (
    //     cookies.remove(key, {domain :'.aibimmobilier.fr'}),
    //     cookies.remove(key, {domain :'.aibimmobilier.com'}),
    //     cookies.remove(key, {domain :'www.aibimmobilier.fr'}),
    //     cookies.remove(key, {domain :'www.aibimmobilier.com'})
    // ))
    // cookies.remove("CookieConsent");    
    setGoToHome(true)
  



    // cookies.remove("_ga", {domain :'.aibimmobilier.fr'});
    // cookies.remove("_ga_ZDMKV30PCH", {domain :'.aibimmobilier.fr'});
    // cookies.remove("_ga", {domain :'.aibimmobilier.com'});
    // cookies.remove("_ga_ZDMKV30PCH", {domain :'.aibimmobilier.com'});

    // cookies.remove("_ga");
    // cookies.remove("_ga_ZDMKV30PCH");
    // cookies.remove("CookieConsent");
    // history.go(0);
    
    
  } 


  

  return (
    
    
    
    <React.Fragment>
    
             <Container className="containerAib">
     

     <div className="row">
    <div className="col-md-12 ">
        <ol className="breadcrumb">
            <li className="active">Mentions légales</li>
        </ol>
    </div>
    </div>



<div className="row divaibblock addpadding">


    <div className="col-md-10 col-md-offset-1 ">
        <p>{flecheCarreRight} SASU au capital de 900 € Siège Social : 22 place de l'église 78660 ABLIS </p>
        <p>{flecheCarreRight} RCS Versailles 809 592 082 Carte Professionnelle de Transaction délivrée par CCI Paris Ile de France N° CPI 7801 2016 000 011 267</p>
        <p>{flecheCarreRight} APE 6831 Z Activités : Transactions Immobilières soumises à la TVA </p>
        <p>{flecheCarreRight} Assurance Allianz N° 8046614</p>
        


    </div>

    <div className="col-md-12">
        
        <p>{flecheCarreRight} Médiateur : AME CONSO<br/>
        
        En cas de litige entre le professionnel et le consommateur, ceux-ci s’efforceront de trouver une solution amiable.<br/>
        A défaut d’accord amiable, le consommateur a la possibilité de saisir gratuitement le médiateur de la consommation dont relève le professionnel, à savoir AME CONSO, dans un délai d’un an à compter de la réclamation écrite adressée au professionnel.<br/>
        La saisine du médiateur de la consommation devra s’effectuer :<br/>
        <span className="decaleTxtDroitePlus">{flecheCarreRight} soit en complétant le formulaire prévu à cet effet sur le site internet de l’AME CONSO <a target="_blank" rel="noopener noreferrer" href="https://www.mediationconso-ame.com">https://www.mediationconso-ame.com</a><br/></span>
        <span className="decaleTxtDroitePlus">{flecheCarreRight} soit par courrier adressé à l’AME CONSO, 197 Boulevard Saint-Germain, 75007 Paris.</span>
        </p>
    </div>

    <div className="col-md-12 ">
        <p>{flecheCarreRight} Protection des données personnelles (conformément au règlement européen (UE) 2016/679)
        </p>
        </div>
        <div className=" col-md-12 centerTxt">
        
        {iconDownload}  <a href={fileRGPD} target="_blank" rel="noopener noreferrer"> Télécharger le RGPD</a> 
       
   </div>
        <p>
        {flecheCarreRight} Les cookies<br/> 
        Lors de la consultation de notre site, des informations sont susceptibles d'être enregistrées dans des fichiers « cookies » installés sur votre ordinateur, tablette ou téléphone mobile.
        Cette page vous permet de gérer l'enregistrement de ces cookies.<br/>
        <span className="decaleTxtDroitePlus">{flecheCarreRight} Mesure d'audience, Google Analytics</span><br/>
        
        <span className="decaleTxtDroitePlus">Les services de mesure d'audience permettent de générer des statistiques anonymes et<br/></span><span className="decaleTxtDroitePlus">aggrégées de fréquentation utiles à l'amélioration de notre site.</span>
        </p>
        <p><span className="decaleTxtDroitePlus"><button className="btn btn-primary buttonInfo" onClick={paramCookies}>Désactiver vos cookies</button></span>
        </p>
   
        
        
        


   
</div>
</Container>
 </React.Fragment>
    
  )
}

export default Mentionslegales;