import React from 'react';

import { Navbar, Nav } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import { NavLink } from "react-router-dom";

//import styled from 'styled-components'
import '../css/App.scss';
/*const Styles = styled.div`
   .navbar{
       background-color: #222;
   }
   .navbar-nav .nav-link {
       color: #bbb;

       &:hover {
           color: white;
       }
   }
`;
*/

export const NavigationBar = () => (


    <Navbar variant="light" bg="light" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <NavLink activeClassName="menuSelected" className="menuAib" to="/">
                    <FontAwesomeIcon icon={faHome} />
                </NavLink>
                <NavLink id="agenceLink" activeClassName="menuSelected" className="menuAib mnu" to="/agence">
                    Agence
            </NavLink>
                <NavLink id="acheterLink" activeClassName="menuSelected" className="menuAib mnu" to="/acheter">
                    Acheter
            </NavLink>
                <NavLink id="locLink" activeClassName="menuSelected" className="menuAib mnu" to="/louer">
                    Louer
                </NavLink>
                <NavLink id="vendreLink" activeClassName="menuSelected" className="menuAib mnu" to="/vendre">
                    Estimer
                </NavLink>
                <NavLink id="contactLink" activeClassName="menuSelected" className="menuAib mnu" to="/contact">
                    Nous Contacter
                </NavLink>
                <NavLink id="outilsLink" activeClassName="menuSelected" className="menuAib mnu" to="/outils">
                    Outils Pratiques
                </NavLink>
                <NavLink id="partenairesLink" activeClassName="menuSelected" className="menuAib mnu" to="/partenaires">
                    Nos Partenaires
                </NavLink>
                <NavLink id="honorairesLink" activeClassName="menuSelected" className="menuAib mnu" to="/Honoraires">
                    Honoraires
                </NavLink>
                <NavLink id="biensVendusLink" activeClassName="menuSelected" className="menuAib mnu" to="/biensvendus">
                    Nos Biens Vendus/Loués
                </NavLink>                
            </Nav>
        </Navbar.Collapse>


    </Navbar>


)

export default NavigationBar
