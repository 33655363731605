import React, { useContext } from "react";
import { Row, Col} from 'react-bootstrap';
import '../css/App.scss';
import contextannonces from './contextannonces'

import { useHistory} from "react-router-dom";
import styled from 'styled-components'

const SpanSlyled = styled.span`
cursor: pointer; 
 
`;


const Footer = () =>{
 // const {listeAnnonces, updateListeAnnonces}  = useContext(contextannonces);
 let history = useHistory();
 
 const clicMentionslegales = ()=>{
   history.push('/mentionslegales')
 } 

 
 const { updateDateTimeSite } = useContext(contextannonces);

  return (

  

<React.Fragment>
    <Row>
     
      <Col md={12} className="footer">
    © copyright 2016 AIB Immobilier. Tous droits réservés. |  <span className="hyperlinkempty" onClick={clicMentionslegales}>Mentions Légales</span>
    
    
    </Col>
    
    </Row><Row>
    <Col  md={12}  className="datetimesiteupdated">

    <span >Données mises à jour le {updateDateTimeSite}</span>

    </Col>
    </Row>


   </React.Fragment>


  )
}

export default Footer;