import React, { useState } from "react";

import GesAbefore2021 from "../images/dpe_ges/GES_Before_2021/ges_A_before.png";
import GesBbefore2021 from "../images/dpe_ges/GES_Before_2021/ges_B_before.png";
import GesCbefore2021 from "../images/dpe_ges/GES_Before_2021/ges_C_before.png";
import GesDbefore2021 from "../images/dpe_ges/GES_Before_2021/ges_D_before.png";
import GesEbefore2021 from "../images/dpe_ges/GES_Before_2021/ges_E_before.png";
import GesFbefore2021 from "../images/dpe_ges/GES_Before_2021/ges_F_before.png";
import GesGbefore2021 from "../images/dpe_ges/GES_Before_2021/ges_G_before.png";
import GesValbefore2021 from "../images/dpe_ges/GES_Before_2021/ges_val.png";

import GesAafter from "../images/dpe_ges/GES_After_2021/A.png";
import GesBafter from "../images/dpe_ges/GES_After_2021/B.png";
import GesCafter from "../images/dpe_ges/GES_After_2021/C.png";
import GesDafter from "../images/dpe_ges/GES_After_2021/D.png";
import GesEafter from "../images/dpe_ges/GES_After_2021/E.png";
import GesFafter from "../images/dpe_ges/GES_After_2021/F.png";
import GesGafter from "../images/dpe_ges/GES_After_2021/G.png";

import GesASelectAfter from "../images/dpe_ges/GES_After_2021/A_Select.png";
import GesBSelectAfter from "../images/dpe_ges/GES_After_2021/B_Select.png";
import GesCSelectAfter from "../images/dpe_ges/GES_After_2021/C_Select.png";
import GesDSelectAfter from "../images/dpe_ges/GES_After_2021/D_Select.png";
import GesESelectAfter from "../images/dpe_ges/GES_After_2021/E_Select.png";
import GesFSelectAfter from "../images/dpe_ges/GES_After_2021/F_Select.png";
import GesGSelectAfter from "../images/dpe_ges/GES_After_2021/G_Select.png";
// import GesVal from '../images/dpe_ges/GES_After_2021/dpe_val.png'

// import DpeA from '../images/dpe_ges/DPE_Before_2021/dpe_A1.png'
// import DpeB from '../images/dpe_ges/DPE_Before_2021/dpe_B1.png'
// import DpeC from '../images/dpe_ges/DPE_Before_2021/dpe_C1.png'
// import DpeD from '../images/dpe_ges/DPE_Before_2021/dpe_D1.png'
// import DpeE from '../images/dpe_ges/DPE_Before_2021/dpe_E1.png'
// import DpeF from '../images/dpe_ges/DPE_Before_2021/dpe_F1.png'
// import DpeG from '../images/dpe_ges/DPE_Before_2021/dpe_G1.png'
// import DpeVal from '../images/dpe_ges/DPE_Before_2021/dpe_val.png'

const Ges = (props) => {
  const [lettreGES] = useState(props.lettreGES[0]);
//   const [lettreGES] = useState("A")  // for debug
  const [numGES] = useState(props.lettreGES[1]);
  const [dateDPEGES] = useState(props.dateDPEGES);
  const [viergeOrNot] = useState(props.lettreGES[0]);
 

  const dateDPEGESPivot = new Date(2021, 6, 1);
  const dateDPEGESSplit = dateDPEGES.split("/");
  const dateDPEGESFormatDate = new Date(
    dateDPEGESSplit[2],
    dateDPEGESSplit[1] - 1,
    dateDPEGESSplit[0]
  ); //YY/MM/DD
  
  const isDPEVierge = () =>{
    if (viergeOrNot.toLowerCase() == "vierge"){
     return <div className='dpeVierge'>DPE Vierge</div>;
    }else{
     return "";
    }
   }  

  const imgSelect = (indexTGES) => {
    let ret = "";

    // console.log(dateDPEGES)
    // console.log(dateDPEGESFormatDate)
    // console.log(dateDPEGESPivot)
    if (dateDPEGESFormatDate >= dateDPEGESPivot) {
      switch (indexTGES) {
        case "A":
          if (indexTGES === lettreGES) {
            ret = <img src={GesASelectAfter} alt={indexTGES} />;
          } else {
            ret = <img src={GesAafter} alt={indexTGES} />;
          }
          break;
        case "B":
          if (indexTGES === lettreGES) {
            ret = <img src={GesBSelectAfter} alt={indexTGES} />;
          } else {
            ret = <img src={GesBafter} alt={indexTGES} />;
          }
          break;
        case "C":
          if (indexTGES === lettreGES) {
            ret = <img src={GesCSelectAfter} alt={indexTGES} />;
          } else {
            ret = <img src={GesCafter} alt={indexTGES} />;
          }
          break;
        case "D":
          if (indexTGES === lettreGES) {
            ret = <img src={GesDSelectAfter} alt={indexTGES} />;
          } else {
            ret = <img src={GesDafter} alt={indexTGES} />;
          }
          break;
        case "E":
          if (indexTGES === lettreGES) {
            ret = <img src={GesESelectAfter} alt={indexTGES} />;
          } else {
            ret = <img src={GesEafter} alt={indexTGES} />;
          }
          break;
        case "F":
          if (indexTGES === lettreGES) {
            ret = <img src={GesFSelectAfter} alt={indexTGES} />;
          } else {
            ret = <img src={GesFafter} alt={indexTGES} />;
          }
          break;
        case "G":
          if (indexTGES === lettreGES) {
            ret = <img src={GesGSelectAfter} alt={indexTGES} />;
          } else {
            ret = <img src={GesGafter} alt={indexTGES} />;
          }
          break;
      }
    } else {
      switch (indexTGES) {
        case "A":
          ret = <img src={GesAbefore2021} alt={indexTGES} />;
          break;
        case "B":
          ret = <img src={GesBbefore2021} alt={indexTGES} />;
          break;
        case "C":
          ret = <img src={GesCbefore2021} alt={indexTGES} />;
          break;
        case "D":
          ret = <img src={GesDbefore2021} alt={indexTGES} />;
          break;
        case "E":
          ret = <img src={GesEbefore2021} alt={indexTGES} />;
          break;
        case "F":
          ret = <img src={GesFbefore2021} alt={indexTGES} />;
          break;
        case "G":
          ret = <img src={GesGbefore2021} alt={indexTGES} />;
          break;
      }
    }
    return ret;
  };

  const afficheGESImg = (indexTGES) => {
    let ret = <span></span>;

    if (dateDPEGESFormatDate >= dateDPEGESPivot) {
      if (indexTGES === lettreGES) {
        ret = (
          <td>
            {numGES}&nbsp;kg CO<sub>2</sub>/m<sup>2</sup>/an&nbsp;&nbsp;&nbsp;
          </td>
        );
      } else {
        ret = <td>&nbsp;&nbsp;&nbsp;</td>;
      }
    } else {
      if (indexTGES === lettreGES) {
        ret = (
          <td
            className="addDPE coverDpeGesImg"
            style={{
              backgroundImage: `url('${GesValbefore2021}')`,
              backgroundRepeat: "no-repeat",
            }}
          >
            {numGES}&nbsp;&nbsp;&nbsp;
          </td>
        );
      } else {
        ret = <td>&nbsp;&nbsp;&nbsp;</td>;
      }
    }

    // if (numGES === 0 || numGES === undefined || numGES === "") {
    //     ret = <td className="dpeUnknown">DPE non communiqué</td>
    // }

    return ret;
  };

  // const SelectGESImg = ()

  return (
    <React.Fragment>
      {isDPEVierge()}
      <table className="dpetable">
        <tbody>
          <tr>
            <td colSpan="2" align="center">
              <span className="reducefont">Faible émission de GES</span>
            </td>
          </tr>
          <tr>
            <td>
              {/* <img src={GesAbefore2021} alt="A" /> */}
              {imgSelect("A")}
            </td>
            {afficheGESImg("A")}
          </tr>

          <tr>
            <td>
              {/* <img src={GesBbefore2021} alt="B" /> */}
              {imgSelect("B")}
            </td>
            {afficheGESImg("B")}
          </tr>
          <tr>
            <td>
              {/* <img src={GesCbefore2021} alt="C" /> */}
              {imgSelect("C")}
            </td>
            {afficheGESImg("C")}
          </tr>
          <tr>
            <td>
              {/* <img src={GesDbefore2021} alt="D" /> */}
              {imgSelect("D")}
            </td>
            {afficheGESImg("D")}
          </tr>
          <tr>
            <td>
              {/* <img src={GesEbefore2021} alt="E" /> */}
              {imgSelect("E")}
            </td>
            {afficheGESImg("E")}
          </tr>
          <tr>
            <td>
              {/* <img src={GesFbefore2021} alt="F" /> */}
              {imgSelect("F")}
            </td>
            {afficheGESImg("F")}
          </tr>
          <tr>
            <td>
              {/* <img src={GesGbefore2021} alt="G" /> */}
              {imgSelect("G")}
            </td>
            {afficheGESImg("G")}
          </tr>

          <tr>
            <td colSpan="2" align="center">
              <span className="reducefont">Forte émission de GES</span>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Ges;
