import React, {useState, useEffect} from "react";
import axios from "axios";
import { Row, Col, Container } from 'react-bootstrap';
import Modalpopuplogique from './modalpopuplogique';
import Modalpopup from './modalpopup';
import $ from 'jquery';
import Gotodisplay from "./gotodisplay";
import MessageInfosRecueillies from './messageInfoRecueillies'



const Vendre = () =>{
  const [gotop, setGotop] = useState(true);
  Gotodisplay(gotop,setGotop)

  useEffect(() => {
        
    $(".mnu").removeClass("menuSelected") 
    $("#vendreLink").addClass("menuSelected") 
  })   
  const { affiche, toggle } = Modalpopuplogique();


  
  const [codepostal, setCodepostal] = useState('');
  const [villeliste, setVilleliste] = useState([]);
  const [ville, setVille] = useState('Choisir une ville');
  const [typedebien, setTypedebien] = useState("Type de bien");
  const [prenom, setPrenom] = useState('');
  const [nom, setNom] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emailconfirmation, setEmailconfirmation] = useState('');
  const [message, setMessage] = useState('');
  const [complementAdresse, setComplementAdresse] = useState('')
  const [rue, setRue] = useState('')
  const [numero, setNumero] = useState('')
  const [rgpd, setRgpd] = useState(false);


  const [prenomError, setPrenomError] = useState('');
  const [nomError, setNomError] = useState('');
  const [typedebienError, setTypedebienError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailconfirmationError, setEmailconfirmationeError] = useState('');
  const [codepostalError, setCodepostalError] = useState('');
  const [villeError, setVilleError] = useState('');
  const [complementAdresseError, setComplementAdresseError] = useState('');
  const [rueError, setRueError] = useState('');
  const [numeroError, setNumeroError] = useState('');
  const [rgpdError, setRgpdError] = useState('');


  

  const typedebienavendre = ["Maison","Appartement","Terrain", "Parking","Fond de commerce","Immeuble", "Local Professionnel"]  

  const [formValid, setFormValid] = useState(0);


const [codepostal1, setCodepostal1] = useState('')
const [complementAdresse1, setComplementAdresse1] = useState('')
const [rue1, setRue1] = useState('')
const [numero1, setNumero1] = useState('')



const [typedebien1, setTypedebien1] = useState('')
const [prenom1, setPrenom1] = useState('')
const [nom1, setNom1] = useState('')
const [phone1, setPhone1] = useState('')
const [email1, setEmail1] = useState('')
const [emailconfirmation1, setEmailconfirmation1] = useState('')
const [ville1, setVille1] = useState('')



  const init = () =>{
    setCodepostal1('');
    setTypedebien1("Je suis intérressé par");
    setVille1("Choisir une ville")
    setPrenom1('');
    setNom1('');
    setPhone1('');
    setEmail1('');
    setEmailconfirmation1('');
    setMessage('');
    setNumero1('');
    setRue1('');
    setComplementAdresse1('');

    setRueError('');
    setComplementAdresseError('');
    setNumeroError('');
    setPrenomError('');
    setNomError('');
    setTypedebienError('');
    setPhoneError('');
    setEmailError('');
    setEmailconfirmationeError('');
    setCodepostalError('');
    setVilleError('');
    setFormValid(0);
    setRgpdError('');
    setRgpd(!rgpd);
  }

  const getCodePostal = codepostal =>{
    axios.post(
      
      'https://www.aibimmobilier.fr:444/getville',
      //'http://localhost:3001/getville',
      {
        codepostal: codepostal,
      }
    ).then(res => {
      setVilleliste(res.data)
      if ((res.data).length === 0){
        setCodepostalError("Code postal n'existe pas !")
        setCodepostal('')
      }
      
    })
  }

  const handleSubmit = event => {
    event.preventDefault();
   // console.log(formValid);

    if (formValid === 511) {
      axios.post(
        //'http://localhost:3001/vendre',
        'https://www.aibimmobilier.fr:444/vendre',
        {
          codepostal: codepostal,
          ville: ville,
          typedebien: typedebien,
          nom: nom,
          prenom: prenom,
          phone: phone,
          email: email,
          message: message,
          rue: rue,
          complementadresse: complementAdresse,
          numero: numero

        }
      ).then(res => {
        toggle();
        init();
      })
    } else {
      (prenom === '') ? setPrenomError("Merci de renseigner votre prénom !") : setPrenomError("");
      (nom === '') ? setNomError("Merci de renseigner votre nom !") : setNomError("");
      (phone === '') ? setPhoneError("Merci de renseigner votre téléphone !") : setPhoneError("");
      (email === '') ? setEmailError("Merci de renseigner votre email !") : setEmailError("");
      (emailconfirmation === '') ? setEmailconfirmationeError("Merci de renseigner votre email !") : setEmailconfirmationeError("");
      (codepostal === '') ? setCodepostalError("Merci de renseigner un code postal !") : setCodepostalError("");
      (typedebien === 'Type de bien') ? setTypedebienError("Merci de sélectionner un type de bien !") : setTypedebienError("");
      (ville === 'Choisir une ville') ? setVilleError("Merci de sélectionner une ville !") : setVilleError("");
      (rgpd === false) ? setRgpdError("Merci de valider le RGPD !") : setRgpdError("");


      
    }
  }




  /////////////// Validation ///////////

  const validateMessage = valTextaerea => {
    //console.log(valTextaerea)
    //setMessage(valTextaerea)
    setMessage(valTextaerea)
  }

const afficheVille = () => {
  
  if (villeliste.length > 0) {
    return (
    
      <div className="form-group">
      <label htmlFor="ville" className="control-label above"></label>
      
      <span className="mandatory">*</span><select id="ville" name="ville" type="text" value={ville1}  onChange={e => validateVille(e.target.value)}>
      <option>Choisir une ville</option>
          {villeliste.map((item,index) => (
            <option key={index} >{item}</option>
          ))}
      </select>
      <div className="help-block with-errors">&nbsp;{villeError}</div>
      </div>
    )

  }else {
    return ""
  }
}


const validateVille = valfield => {
  let valid = false;
  (valfield !== "Choisir une ville") ? valid = true : valid = false;
  let champBit = formValid;
  if (valid){
    setVille(valfield)
    champBit = champBit | (1 << 7)
    setVilleError("")
    setFormValid(champBit)
  }else{
    champBit = champBit & ~(1 << 7)
    setVilleError("Choisir une ville")
    setVille("Choisir une ville")
    setFormValid(champBit)
  }
  setVille1(valfield)
}


const validateTypedeBien = valfield => {
  let valid = false;
  let champBit = formValid;
  (valfield !== "Type de bien") ? valid = true : valid = false;
  if (valid){
    setTypedebien(valfield)
    champBit = champBit | (1 << 5)
    setTypedebienError("")
    setFormValid(champBit)
  }else{
    champBit = champBit & ~(1 << 5)
    setTypedebienError("Merci de choisir un type de bien")
    setTypedebien("Type de bien")
    setFormValid(champBit)
  }
  setTypedebien1(valfield)
}



const validateNumro = valfield => {
  let valid = false;
  let valid1 = false;
  let validEmpty = false;
  let numbers = /^[-+]?[0-9]+$/;
  (valfield.length >= 1) ? valid = true : valid = false;
  (valfield.match(numbers)) ? valid1 = true : valid1 = false;
  (valfield.length === 0) ? validEmpty = true : validEmpty = false;

if (validEmpty){
  setNumero(valfield)
  setNumeroError("")
}else{
  if (valid && valid1) {
    setNumero(valfield)
    setNumeroError("")
  } else {
    setNumeroError("Numéro invalide !")
  }

}
  setNumero1(valfield)

}

const validateRue = valfield => {
  let valid = false;
  let valid1 = false;
    (valfield.length > 2) ? valid = true : valid = false;
    (valfield.length  === 0) ? valid1 = true : valid1 = false;

if (valid1){
  setRue(valfield)
  setRueError("")
}else{
  if (valid) {
    setRue(valfield)
    setRueError("")
  } else {
    setRueError("Rue invalide !")
  }
  
  }
  setRue1(valfield)
}

const validateComplementAdresse = valfield => {
  let valid = false;
  let valid1 = false;
    (valfield.length > 2) ? valid = true : valid = false;
    (valfield.length  === 0) ? valid1 = true : valid1 = false;

if (valid1){
  setComplementAdresse(valfield)
  setComplementAdresseError("")
}else{
  if (valid) {
    setComplementAdresse(valfield)
    setComplementAdresseError("")
  } else {
    setComplementAdresseError("Complément d'adresse invalide !")
  }
  
  }
  setComplementAdresse1(valfield)
}


const validateCodepostal = valfield => {
  let valid = false;
  let valid1 = false;
  let validEmpty = false;
  let numbers = /^[-+]?[0-9]+$/;

  (valfield.length === 5) ? valid = true : valid = false;
  (valfield.match(numbers)) ? valid1 = true : valid1 = false;
  (valfield !== "Code Postal") ? validEmpty = true : validEmpty = false;

  
  let champBit = formValid;
  if (valid && valid1 && validEmpty) {
    setCodepostal(valfield)
    setCodepostalError("")
   champBit = champBit | (1 << 6)
    setFormValid(champBit)
    getCodePostal(valfield)

  } else {
    setVilleliste([])
    setCodepostalError("Code Postal invalide")
    champBit = champBit & ~(1 << 6)
    setFormValid(champBit)
  }
  setCodepostal1(valfield)
}






  /* Validation du Prénom */
  const validatePrenom = valfield => {
    let valid = false;
    let champBit = formValid;

    if (valfield.length > 3)
      valid = true;
    if (valid) {
      setPrenom(valfield)
      setPrenomError("")
      champBit = champBit | (1 << 0)
      setFormValid(champBit)
    } else {
      setPrenomError("Prénom invalide")
      champBit = champBit & ~(1 << 0)
      setFormValid(champBit)
    }
    setPrenom1(valfield)
  }

  /* Validation du Nom */
  const validateNom = valfield => {
    let valid = false;
    let champBit = formValid;

    if (valfield.length > 3)
      valid = true;


    if (valid) {
      setNom(valfield)
      setNomError("")
      champBit = champBit | (1 << 1)
      setFormValid(champBit)
    } else {
      setNomError("Nom invalide")
      champBit = champBit & ~(1 << 1)
      setFormValid(champBit)
    }
    setNom1(valfield)
  }

  /* Validation du Phone */
  const validatePhone = valfield => {
    let champBit = formValid;
    let valid = false;
    let valid1 = false;
    let numbers = /^[-+]?[0-9]+$/;

    (valfield.length === 10) ? valid = true : valid = false;
    (valfield.match(numbers)) ? valid1 = true : valid1 = false;


    if (valid && valid1) {
      setPhone(valfield)
      setPhoneError("")
      champBit = champBit | (1 << 2)
      setFormValid(champBit)
    } else {
      setPhoneError("Téléphone invalide")
      champBit = champBit & ~(1 << 2)
      setFormValid(champBit)
    }
    setPhone1(valfield)
  }

  /* Validation du Email */
  const validateEmail = valfield => {
    let champBit = formValid;
    let valid = false;
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    (valfield.match(regexEmail)) ? valid = true : valid = false;

    if (valid) {
      setEmail(valfield)
      setEmailError("")
      champBit = champBit | (1 << 3)
      setFormValid(champBit)
    } else {
      setEmailError("Email invalide")
      champBit = champBit & ~(1 << 3)
      setFormValid(champBit)
    }
    setEmail1(valfield)
  }

  const validateRgpd = () => {
    
    let champBit = formValid;
    let valid = false;
    
    (rgpd) ? valid = false : valid = true;
    if (valid) {
      champBit = champBit | (1 << 8)
      setRgpdError("")
      setFormValid(champBit)
    }else{

      champBit = champBit & ~(1 << 8)
      setRgpdError("Merci de valider le RGPD !")
      setFormValid(champBit)
    }

    setRgpd(!rgpd)
    
  }
  const fileRGPD = "/2018-informations_RGPD_AIB.pdf"

  /* Validation du EmailConfirmation */
  const validateEmailconfirmation = valfield => {
    setEmailconfirmation1(valfield)
    let champBit = formValid;
    let valid = false;
    let valid1 = false;
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    (valfield.match(regexEmail)) ? valid = true : valid = false;
    (valfield === email) ? valid1 = true : valid1 = false;

    if (valid) {
      setEmailconfirmation(valfield)
      setEmailconfirmationeError("")
    } else {
      setEmailconfirmationeError("Email invalide")
      champBit = champBit & ~(1 << 4)
      setFormValid(champBit)
      return;
    }

    if (valid1) {
      setEmailconfirmation(valfield)
      setEmailconfirmationeError("")
    } else {
      setEmailconfirmationeError("Email différent")
      champBit = champBit & ~(1 << 4)
      setFormValid(champBit)

    }


    if (valid && valid1) {
      setEmailconfirmation(valfield)
      setEmailconfirmationeError("")
      champBit = champBit | (1 << 4)
      setFormValid(champBit)
    } else {
      setEmailconfirmationeError("Email invalide")
      champBit = champBit & ~(1 << 4)
      setFormValid(champBit)
    }
    
  }

  return (
    
<React.Fragment>

<form onSubmit={handleSubmit}>
  <Container className="containerAib">
    <Row>
      <div className="col-md-12 ">
        <ol className="breadcrumb">
          <li className="active">&nbsp;&nbsp;Estimer un bien</li>
        </ol>
      </div>
    </Row>
    <Row className="aib-agence ">

      <Col md={{ span: 10, offset: 1 }} className="divaibblock1 ">
        
        <Row >

          <Col md={6} >
          <p className="txttitre">Vos coordonnées</p>
            <div className="form-group">
              <label htmlFor="prenom" className="control-label above"></label>
              <span className="mandatory">*</span><input size="16" placeholder="Votre prénom" id="prenom" name="prenom" type="text" value={prenom1} onChange={e => validatePrenom(e.target.value)}/>
              <div className="help-block with-errors">&nbsp;{prenomError}</div>
            </div>
            <div className="form-group">
              <label htmlFor="nom" className="control-label above"></label>
              <span className="mandatory">*</span><input size="16" placeholder="Votre nom" id="nom" name="nom" type="text" value={nom1}  onChange={e => validateNom(e.target.value)} />
              <div className="help-block with-errors">&nbsp;{nomError}</div>
            </div>
            <div className="form-group">
                    <label htmlFor="phone" className="control-label above"></label>
                    <span className="mandatory">*</span><input size="16" placeholder="Votre téléphone" id="phone" name="phone" value={phone1}  type="text" onChange={e => validatePhone(e.target.value)} />
                    <div className="help-block with-errors">&nbsp;{phoneError}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="control-label above"></label>
                    <span className="mandatory">*</span><input size="20" placeholder="Votre email" id="email" name="email" value={email1}  type="text" onChange={e => validateEmail(e.target.value)} />
                    <div className="help-block with-errors">&nbsp;{emailError}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="emailconfirmation" className="control-label above"></label>
                    <span className="mandatory">*</span><input size="20" placeholder="Confirmez votre email" id="emailconfirmation" value={emailconfirmation1}  name="emailconfirmation" type="text" onChange={e => validateEmailconfirmation(e.target.value)} />
                    <div className="help-block with-errors">&nbsp;{emailconfirmationError}</div>
                  </div>
                  </Col>
                  <Col md={5} >
            <p className="txttitre">Adresse et type de bien</p>

            <div className="form-group">
              <label htmlFor="typedebienavendre" className="control-label above"></label>
              
              <span className="mandatory">*</span><select id="typedebienavendre" name="typedebienavendre" type="text" value={typedebien1} onChange={e => validateTypedeBien(e.target.value)}>
                <option >Type de bien</option>
                {typedebienavendre.map((item,index) => (
                  <option key={index} >{item}</option>
                ))}
            </select>
            <div className="help-block with-errors">&nbsp;{typedebienError}</div>
            </div>
           

            <div className="form-group">
              <label htmlFor="numero" className="control-label above"></label>
              <input size="6" placeholder="Numéro" id="numero" name="numero" value={numero1} type="text" onChange={e => validateNumro(e.target.value)} />
              <div className="help-block with-errors">&nbsp;{numeroError}</div>
            </div>

            <div className="form-group">
              <label htmlFor="rue" className="control-label above"></label>
              <input size="30" placeholder="Rue" id="rue" name="rue" value={rue1} type="text" onChange={e => validateRue(e.target.value)} />
              <div className="help-block with-errors">&nbsp;{rueError}</div>
            </div>
            <div className="form-group">
              <label htmlFor="complementAdresse" className="control-label above"></label>
              <input size="30" placeholder="Complément d'adresse" id="complementAdresse" name="complementAdresse" value={complementAdresse1} type="text" onChange={e => validateComplementAdresse(e.target.value)} />
              <div className="help-block with-errors">&nbsp;{complementAdresseError}</div>
            </div>

            <div className="form-group">
              <label htmlFor="codepostal" className="control-label above"></label>
              <span className="mandatory">*</span><input size="16" placeholder="Code Postal" id="codepostal" name="codepostal" value={codepostal1} type="text" onChange={e => validateCodepostal(e.target.value)} />
              <div className="help-block with-errors">&nbsp;{codepostalError}</div>
            </div>
            {afficheVille()}
           
            <div className="form-group">
                    <label htmlFor="message" className="control-label above">Texte libre (facultatif)</label>
                    <textarea id="message" name="message" onChange={e => validateMessage(e.target.value)} value={message} rows="5" cols="30">
                      {message}
                    </textarea>

                  </div>
          </Col>
          

        </Row>
        <Row>
              <Col md={12} >
                      <div className="form-group">
                        <label htmlFor="rgpd" className="control-label above"></label>
                        <input id="rgpd" type="checkbox" className="alignLeft" checked={rgpd}  onChange={e => validateRgpd()}/>
                        
                    <span className="alignLeft">J'accepte le traitement de mes données personnelles conformément au RGPD. 
                    <a href={fileRGPD} target="_blank" rel="noopener noreferrer"> En savoir plus</a> 
                    </span>
                    <div className="help-block with-errors">&nbsp;{rgpdError}</div>
                    </div>
                    </Col>
        </Row>
        
        <Row>
                <Col md={12} >
                      <MessageInfosRecueillies/>
                </Col>
              </Row>
        
        <Row>
                <Col md={{ span: 4, offset: 5 }} >
                  <div className="form-group">
                    <button className="btn btn-primary buttonInfo" >Envoyer</button>
                  </div>
                </Col>

              </Row>


      </Col>
    </Row>
  </Container>

</form>
<Modalpopup
  affiche={affiche}
  cache={toggle}
  email={email}
/>
</React.Fragment>
  )
}

export default Vendre;