import React, {useEffect, useState} from "react";
import { Row, Col, Container } from 'react-bootstrap';
import LogoBienIci from "../images/logopartenaires/Bien-ici.jpg"
import LogoUnis from "../images/logopartenaires/logo-UNIS.jpg"
import LogoSeLoger from "../images/logopartenaires/SeLoger.png"
import LogoMeilleursAgents from "../images/logopartenaires/meilleursagents.png"
import LogoLeBonCoin from "../images/logopartenaires/LebonCoin.jpg"
import LogoVerspiren from "../images/logopartenaires/verspiren.png"
import LogoAvantageCourtage from "../images/logopartenaires/logo_AC.png"
import LogoAgenceUnies from "../images/logopartenaires/logo_LAU.jpg"
import LogoAvendreALouer from "../images/logopartenaires/Logo_Avdre_aLOUER.png"
import LogoLogicImmo from "../images/logopartenaires/LOGIC-IMMO-LOGO.png"
import LogoAmeConso from "../images/logopartenaires/AME-conso.jpg"


import Partenaire from "./partenaire"
import $ from 'jquery'

import Gotodisplay from "./gotodisplay";


const Partenaires = () => {
  const [gotop, setGotop] = useState(true);
  Gotodisplay(gotop,setGotop)
  useEffect(() => {

    $(".mnu").removeClass("menuSelected")
    $("#partenairesLink").addClass("menuSelected")
  })

  const arrayLogo = [
    [LogoBienIci,"https://www.bienici.com/"], 
    [LogoUnis ,"https://www.unis-immo.fr/"],
    [LogoSeLoger,"https://www.seloger.com/"], 
    [LogoMeilleursAgents,"https://www.meilleursagents.com/"],
    [LogoLeBonCoin,"https://www.leboncoin.fr/_immobilier_/offres/"],
    [LogoVerspiren,"https://www.verspieren.com/fr/"], 
   // [LogoAvantageCourtage,"https://www.avantagecourtage.fr/contactez-nous/virginie-aurat/" ],
    [LogoAvantageCourtage,"https://www.avantagecourtage.fr/" ],
    [LogoAgenceUnies,"http://www.lesagencesunies.fr/"  ],
    [LogoAvendreALouer,"https://www.avendrealouer.fr/"  ],
    [LogoLogicImmo,"https://www.logic-immo.com/"  ],
    [LogoAmeConso,"https://www.mediationconso-ame.com/"  ]



    

  ]

  

  return (
    <Container className="containerAib">
      <Row>
        <div className="col-md-12 ">
          <ol className="breadcrumb">
            <li className="active">&nbsp;&nbsp;Nos Partenaires</li>
          </ol>
        </div>
      </Row>
      <Row className="reduceSpace">

        {arrayLogo.map((item, index) => (
          <Col md={4}  key={index} className="poslogo">
            <Partenaire 
              key={index}
              pathimg={item[0]}
              link={item[1]}
            />
            </Col>
        )
        )}
        

      </Row>
    </Container>
  )
}

export default Partenaires;