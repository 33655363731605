import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import { Map, Marker, TileLayer, Tooltip } from 'react-leaflet'

import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";



//import {https://unpkg.com/leaflet@1.6.0/dist/leaflet.css}
// Latitude : 48.515727 | Longitude : 1.835354








const MapAib = () => {

  const flecheCarreRight = <FontAwesomeIcon icon={faMapMarkerAlt} className="couleurAIB"  size = '3x'/>
  const positionAiB = [48.515727 , 1.835354]
  const iconMarkup = renderToStaticMarkup(
    <span>{flecheCarreRight}</span>
  );
  const iconaib = divIcon({
    html: iconMarkup
  });



      return (
        <Map 
          center={positionAiB} 
          zoom={15} 
          style={{ width: '100%', height: '300px'}}
          
          >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          //minZoom= "1"
        />
        <Marker 
        //  data="customdata"
          position={positionAiB}
          icon= {iconaib}
  
        >
          <Tooltip
          permanent= 'true'
          ><span>AIB Immobilier</span></Tooltip>
          
        </Marker>
      </Map>
      );
    }


export default MapAib;

//leaflet-container