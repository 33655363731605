import React, {useEffect} from "react";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'



const bienvenduyesno = (vendu) =>{
    let ret;
    if (vendu === "Yes"){
        ret = ""
    }else{
        ret = (<span><button className="buttonInfo btn btn-primary"><FontAwesomeIcon icon={faAngleDoubleLeft} /> Plus d'infos <FontAwesomeIcon icon={faAngleDoubleRight} /></button></span>)
    }
    return ret
}


const checkIfDateToday = (vente_signature_date_acte, aff_num) => {
    let ret;
    let nowObj = new Date();
    let now = nowObj.getFullYear().toString() + nowObj.getMonth().toString() + nowObj.getUTCDate().toString()
       
    let dateToCheckObj = new Date(vente_signature_date_acte)
    let dateToCheck = dateToCheckObj.getFullYear().toString() + dateToCheckObj.getMonth().toString() + dateToCheckObj.getUTCDate().toString()
    
    
    if (dateToCheck === now){
        ret = <span >Ref : <u>{aff_num}</u></span>
    }else{
        ret = <span >Ref : {aff_num}</span>
    }
    return (ret)
}

const formatPrix = (prix, vendu) =>{
    let ret;
    

    if (prix == null){

        ret =  "Prix non communiqué"
    }else{  
        let prix_str = prix.toString();
        ret = prix_str.substr(0, prix_str.length - 3) + "  " + prix_str.substr(prix_str.length - 3) + " €";
    }
    if (vendu == "Yes")
        ret = (<span>&nbsp;</span>)

    return ret;
    
}   

const bienvenduyesnoChargeHonoraires = (vendu, charge_honoraires) =>{
    let ret =""
    if (vendu == "Yes"){
        ret =(<span>&nbsp;</span>)
    }else{
        ret = (<span className="displaydessousChargeHonoraires">{charge_honoraires}</span>)
    }
    return ret;
}

//import ReactDOM from "react-dom";

//class Bien extends Component {
    const Bien = (props)=>{
        

        useEffect(() => {
        
            $(".mnu").removeClass("menuSelected") 
            if (props.vendu === "Yes"){
                $("#biensVendusLink").addClass("menuSelected") 
            }else{
                $("#acheterLink").addClass("menuSelected")         
            }
          })   
    
          


    const Exclusif = ()=>{
       
        if  (props.vendu === "Yes" ){ 
            return <div className="exclusivitebandeau">Vendu</div>
        }else{

            if (props.type_mandat === "Exclusif" ){
                return <div className="exclusivitebandeau">Exclusivité</div>
            }   else{
                return '';
            }
        }

    }



        return(
            
            
            <React.Fragment>
                <div className="row bloc-bien">
                <div className="col-md-12  p-2">
                    
                    
                    <div className="card divaibblock1  showhide" >
                    <Exclusif/>
                            {props.img}
                            <div className="card-body titreaibmsg ">    
                                <p className="card-text ">
                                    <span className="center txt-titre">{props.titre}</span>
                                    </p><p className="txtrightreducesize ">
                                    {checkIfDateToday(props.vente_signature_date_acte, props.aff_num)}   
                                    {/* <span >Ref : {props.aff_num} </span> */}

                                </p>
                            </div>
                            <div className="card-body ">
                                <p className="card-text ">
                                    <span className="prix">
                                        <span className="displaydessous ">
                                        
                                        {formatPrix(props.vente_prix, props.vendu)}
                                        </span>
                                        {bienvenduyesnoChargeHonoraires(props.vendu, props.charge_honoraires)}
                          
                                    {bienvenduyesno(props.vendu)}
                                    
                                        
                                    </span>
                                </p>
                            </div>
                            
                            
                            
                            
                        </div>
                   
                    </div>
                </div>

  


          </React.Fragment>
            
        );
    }

//}



export default Bien;