import React, { useState, useEffect } from "react";
import $ from 'jquery';
import {  Container, Col, Row } from 'react-bootstrap';
import GoogleStat, { TrackGoogleAnalyticsEvent } from "./googleAnalytics";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

import Gotodisplay from "./gotodisplay";


const Honoraires = () =>{
    const [gotop, setGotop] = useState(true);
    Gotodisplay(gotop,setGotop)

  const flecheCarreRight = <FontAwesomeIcon icon={faCaretRight} className="aib-color" />
  
  

    $(".mnu").removeClass("menuSelected")
    $("#honorairesLink").addClass("menuSelected")

    useEffect(() => {

        $(".mnu").removeClass("menuSelected")
        $("#honorairesLink").addClass("menuSelected")
        TrackGoogleAnalyticsEvent(
              "HonorairesCategory",
              "Honoraires",
              window.location.pathname
        )
            
          
    })
  


  return (
    <React.Fragment>
     

     <Container className="containerAib ">
     <div className="row">
    <div className="col-md-12 ">
        <ol className="breadcrumb">
            <li className="active">&nbsp;&nbsp;Nos honoraires</li>
        </ol>
    </div>
    </div>
    <Row className="aib-agence1 ">



<Col md={{ span: 10, offset: 1 }} className="divaibblock2 ">
<div className="row aib-agence">
    <div className="offset-md-2  col-md-8 ">
        <p className="aib-center">
            <br/><b><u>Honoraires de Transactions Ventes</u></b></p>
    </div>
</div>

<div className="row aib-agence">
    <div className="offset-md-2  col-md-8 ">
        <p className="aib-center"><u>Sur Terrain</u></p>
    </div>
</div>
<div className="row aib-agence">
    <div className="offset-md-3 col-md-7 ">
        <ul className="leaders">
            <li>
                <span>{flecheCarreRight} Forfait Unitaire Toutes Taxes Comprise (TTC) :</span>
                <span><b>9 000,00 € Maximum</b></span>
            </li>
            <li>
                <span>{flecheCarreRight} En cas de pluralité TTC :</span>
                <span><b>7 500,00 € (Maximum par terrain)</b></span>
            </li>
        </ul>
        <br/>
    </div>
</div>

<div className="row aib-agence">
    <div className="offset-md-2 col-md-8 ">
        <p className="aib-center"><u>Sur Appartement, Maison et Commerce</u></p>
    </div>
</div>
<div className="row aib-agence">
    <div className="offset-md-3 col-md-7 ">

        <ul className="leaders">
            {/* <li>
                <span>{flecheCarreRight} De 0 à 180 000 € - Honoraires TTC :</span>
                <span><b>9 000,00 € Maximum</b></span>
            </li>
            <li>
                <span>{flecheCarreRight} De 180 001 à 500 000 € - Honoraires TTC :</span>
                <span><b>5 % Maximum</b></span>
            </li>
            <li>
                <span>{flecheCarreRight} Au-delà de 500 001 € - Honoraires TTC :</span>
                <span><b>4 % Maximum</b></span>
            </li> */}

            {/* <li>
                <span>{flecheCarreRight} Inférieur à 100 000 € - Honoraires TTC :</span>
                <span><b>9 000 € Maximum</b></span>
            </li>
            <li>
                <span>{flecheCarreRight} De 100 001 € à 200 000 € - Honoraires TTC :</span>
                <span><b>10 000 € Maximum</b></span>
            </li>
            <li>
                <span>{flecheCarreRight} De 200 001 € à 300 000 € - Honoraires TTC :</span>
                <span><b>15 000 € Maximum</b></span>
            </li>
            <li>
                <span>{flecheCarreRight} De 300 001 € à 400 000 € - Honoraires TTC :</span>
                <span><b>20 000 € Maximum</b></span>
            </li>            
            <li>
                <span>{flecheCarreRight} De 400 001 € à 500 000 € - Honoraires TTC :</span>
                <span><b>25 000 € Maximum</b></span>
            </li>

            <li>
                <span>{flecheCarreRight} Au-delà de 500 001 € - Honoraires TTC :</span>
                <span><b>5 % Maximum</b></span>
            </li> */}

            <li>
                <span>{flecheCarreRight} De 0 € à 180 000 € - Honoraires TTC :</span>
                <span><b>9 000 € Maximum</b></span>
            </li>

            <li>
                <span>{flecheCarreRight} Au-delà de 180 001 € - Honoraires TTC :</span>
                <span><b>5,5 % Maximum</b></span>
            </li>
            
        </ul>
        <br/>
    </div>
</div>

<div className="row aib-agence">
    <div className="offset-md-3 col-md-7 ">
        <p className="aib-center-neutre">
            <b>Tranches non cumulatives et forfaits non cumulatifs.<br/>
            TVA au taux en vigeur de 20% incluse.<br/>
        <span class="honorairebleu">Les honoraires sont compris à la charge du vendeur.</span><br/>
            </b> <br/>
            <br/>
            </p>
    </div>
</div>





<div className="row aib-agence">
    <div className="offset-md-2 col-md-8 ">
        <p className="aib-center">
            <br/><b><u>Honoraires de Transactions Locations</u></b></p>
    </div>
</div>

<div className="row aib-agence">
    <div className="offset-md-2 col-md-8 ">
        <p className="aib-center"><u>Charge Propriétaire</u></p>
    </div>
</div>
<div className="row aib-agence">
    <div className="offset-md-3 col-md-7 ">
        <ul className="leaders">
            <li>
                <span>{flecheCarreRight} Frais fixe: Publicité et entremise - forfait TTC :</span>
                <span><b>180,00 €</b></span>
            </li>
            <li>
                <span>{flecheCarreRight} Etats des lieux entrée :</span>
                <span><b>3 €/m² TTC</b></span>
            </li>
            <li>
                <span>{flecheCarreRight} Visites et constitution dossier :</span>
                <span><b>5 €/m²</b><br/></span>
            </li>
            <li>
                <span>{flecheCarreRight} Rédaction du bail: </span>
                <span><b>3 €/m²</b><br/></span>
            </li>
        </ul>



    </div>
</div>


<div className="row aib-agence">
    <div className="offset-md-2 col-md-8 ">
        <p className="aib-center"><u>Charge Locataire</u></p>
    </div>
</div>
<div className="row aib-agence">
    <div className="offset-md-3 col-md-7 ">
        <ul className="leaders">
            <li>
                <span>{flecheCarreRight} Etats des lieux entrée : </span><span><b>3 €/m² TTC</b></span>
            </li>
            <li>
                <span>{flecheCarreRight} Visites, constitution dossier : </span><span><b>5 €/m²</b></span>
            </li>
            <li>
                <span>{flecheCarreRight} Rédaction du bail : </span><span><b>3 €/m²</b><br/></span>
            </li>
        </ul>

    </div>
</div>
<div className="row aib-agence">
    <div className="offset-md-3 col-md-7 ">
        <p className="aib-center-neutre">
        <span><b>TVA au taux en vigeur de 20% incluse.</b></span>
        </p>
    </div>
</div>





<div className="row aib-agence">
    <div className="offset-md-2 col-md-8 ">
        <p className="aib-center"><u><b>Honoraires Autres</b></u></p>
    </div>
</div>

<div className="row aib-agence">
    <div className="offset-md-3 col-md-7 ">
        <ul className="leaders">
            <li>
                <span>{flecheCarreRight} Avis de valeur:</span>
                <span><b>Gratuit</b></span>
            </li>
        </ul>


    </div>
</div>
<div className="row aib-agence">
    <div className="offset-md-2 col-md-8 ">
        <p className="aib-center-neutre">
            <span className="aibRedColor"><b>En cas de délégation, nos honoraires sont appliqués selon le barème de l'agence partenaire détenteur du mandat de vente.</b></span>
        </p>
    </div>
</div>





<div className="row aib-agence">
    <div className="offset-md-2 col-md-8 ">
        <p><b><i>Honoraires à compter du 20/09/2023<br/>&nbsp;</i></b></p>
    </div>
</div>
<div className="row aib-agence">
    <div className="offset-md-1 col-md-10 ">
    
    
        <p className="txtCenterHonoraires">
        <hr className="hrHonoraires"/>
        <span >
        SASU Aib Immobilier au capital de 900 € Code APE 6831Z RCS Versailles 809 592 082<br/>
        Carte CPI 7801 2016 000 011 267 délivrée par la CCI Paris Ile-de-France<br/>
        <u>Siège social :</u> 22 Place de l’Eglise – 78660 ABLIS

        </span>
        </p>
    </div>
</div>

</Col></Row>

</Container>

 </React.Fragment>
    
  )
}

export default Honoraires;