import React, { useEffect, useRef, useState } from 'react';
//import BandeauAIB from '../images/bandeau/bandeau_160h.png'
import BandeauAIB1 from '../images/bandeau/yv-8-219-220.png'
import BandeauAIB2 from '../images/bandeau/yv-8-179-181.png'
import BandeauAIB3 from '../images/bandeau/Vue-Aérienne1.png'

/*
import BandeauAIB4 from '../images/bandeau/yv-8-219-220_h689.png'
import BandeauAIB5 from '../images/bandeau/yv-8-179-181_h689.png'
import BandeauAIB6 from '../images/bandeau/Vue-Aérienne1_h689.png'
*/
import Slider from 'react-animated-slider';
//import horizontalCss from 'react-animated-slider/build/horizontal.css';
//import verticalCss from 'react-animated-slider/build/vertical.css';

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'


import '../css/bandeauaccueil.scss'

gsap.registerPlugin(ScrollTrigger);


const descriptiontxt = 'MENONS ET REUSSISSONS VOTRE PROJET ENSEMBLE.'

const slides = [
  {description: descriptiontxt , image:BandeauAIB1},
  {description: descriptiontxt , image:BandeauAIB2},
  {description: descriptiontxt , image:BandeauAIB3}
  
]



const Bandeauaccueil = () =>{

  const [isclicked, setisclicked] = useState(false);
  const t1 =gsap.timeline();
  const popup = useRef(null);  
  useEffect(() => {
    t1.fromTo(popup.current,{
      y: 0,
      autoAlpha: 0,
      rotationZ: 180,
    },{
      y: 0,
      duration: 2,
      autoAlpha: 0.9,
      rotationZ: 0,
       
        
   
  });
  })

  
  const fermermessage = ()=>{

    gsap.to(popup.current,{
      opacity:0,
      duration: 1.5,
    })

    // setisclicked(true);

  }


  return (
    <React.Fragment>
    

    <div>
    
    
    
    
    <Slider
      autoplay= 'true' 
      infinite= 'true' 
      duration='10000'
      buttonDisabled='disabled'
      previousButton={''}
      nextButton={''}
      
      >
        	
      {slides.map((slide, index) =>
      <div className="formatBandeau "
        key={index} 
        style={{ background: `url('${slide.image}') no-repeat center center` }}
       >

        <div className="centerbandeautxt ">
        
        <p>{slide.description}</p>
        </div>


      </div>
      )
      }

    </Slider>
    </div>
    </React.Fragment>
  )
}


export default Bandeauaccueil;
