import React, { useState } from "react";
//import {Slide} from 'react-slideshow-image';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';


import '../css/bienvitrine.scss'
import { useHistory} from "react-router-dom";
import DetailBien from './detailBien';





const BandeauVitrine = (props) => {

	

	const [range1] = useState(props.rangePhotos[0]);
	const [range2] = useState(props.rangePhotos[1]);
	const [range3] = useState(props.rangePhotos[2]);
	
	
	let range1A = []
	let range2A = []
	let range3A = []

	range1.map((item) => {
		
		range1A.push(item[1])
	})


	range2.map((item) => {
		range2A.push(item[1])
	})

	range3.map((item) => {
		range3A.push(item[1])
	})

	
	

	let history = useHistory();
	



	
	const gotoBien = (objBien)=>{
		history.push('/DetailBien/' + objBien[0].aff_num)
		DetailBien.defaultProps = { sel: objBien[0] }

	}


	const displayImage	= () => {
		let ret =[]
		let lengthMin =Math.min(range1.length,range2.length,range3.length)
		
		for (let x = 0; x < lengthMin; x += 1) {
			let r1 = range1[x];
			let r2 = range2[x];
			let r3 = range3[x];
			
			
			ret.push(
				

				<div className="row sliderOverwrite justify-content-around " key="1">
						<div  
							className=" col-md-3 d-none d-md-block addpaddingSlider resizeImgBackground"
							
						
							style={{ background: `url('${r1[1]}') no-repeat  center center`}}
							onClick={() => gotoBien([r1[2]])}
						>
						<div className="titrevitrine">{r1[0]}</div>		
						</div>

						<div  
							className=" col-md-3 d-none d-md-block addpaddingSlider resizeImgBackground"
							
							style={{ background: `url('${r2[1]}') no-repeat center center`}}
							onClick={() => gotoBien([r2[2]])}
						>
						<div className="titrevitrine">{r2[0]}</div>		
						</div>
						<div  
							className=" col-md-3 d-none d-md-block addpaddingSlider resizeImgBackground"
							
							style={{ background: `url('${r3[1]}') no-repeat center center`}}
							onClick={() => gotoBien([r3[2]])}
						>
						<div className="titrevitrine">{r3[0]}</div>		
						</div>
				</div>
						
					
			)
		}
		

		return ret

	}
	
	const img = [
		{
		original: range1A[0],
		thumbnail: range1A[0],
		
	},
	{
		original: range2A[0],
		thumbnail: range2A[0],
		
	},
	{
		original: range3A[0],
		thumbnail: range3A[0],
		
	},
	]

	return (

<React.Fragment>
		
		<div className="slide-container d-none d-md-block">
	
		<Slider 
						autoplay='true'
						infinite='true'
						
						duration='6000'
						//previousButton={<FontAwesomeIcon icon={faChevronCircleLeft} color="#AC0000" size="2x" />}
						//nextButton={<FontAwesomeIcon icon={faChevronCircleRight} color="#AC0000" size="2x" />}
						buttonDisabled='disabled'
						previousButton={''}
						nextButton={''}
						
						
		>
		{displayImage()}
		</Slider>
	
				


		</div>
	


</React.Fragment>
	)
}

export default BandeauVitrine;